import React, { useState } from 'react';
import { cardTableBtn, downloadBtn, filterBtn, HeatMapBtn, textInfoBtn } from '../Charts/ChartConfig/ChartConst';
import { cardButton } from '../../../Constants/Constant';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import "../Chart.css";

export const ChartCardButtons = ({ activeHandler, chartDetail }) => {
    const [btnActive, setBtnActive] = useState(2)
    const [loading, setLoading] = useState(false);

    const {
        filter,
        chart,
        map,
        table,
        download,
    } = cardButton;

    const activeBtnHandler = (prop) => {
        activeHandler(prop);
        setBtnActive(prop);
    }
    const downloadPDF = (prop) => {
        setLoading(true)
        const input = document.getElementById(`chartCardPlace-${prop}`)
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                downloadImage(imgData, chartDetail.title);
                setLoading(false)

                /* this code will reuse for download PDF */
                // pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.output('dataurlnewwindow');

                // const imgProperties = pdf.getImageProperties(imgData);
                // const pdfWidth = pdf.internal.pageSize.getWidth();
                // const pdfHeight =
                //     (imgProperties.height * pdfWidth) / imgProperties.width;

                // pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                // pdf.save(`${chartDetail.title}.pdf`);
            })
    }
    const downloadImage = (blob, fileName) => {
        const fakeLink = window.document.createElement("a");
        fakeLink.style = "display:none;";
        fakeLink.download = fileName;

        fakeLink.href = blob;

        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);

        fakeLink.remove();
    };
    return (
        <>
            <ul className="chartButton float-end nav nav-pills nav-flush mb-auto text-center" data-html2canvas-ignore>
                {
                    filterBtn.find(item => item === chartDetail?.chartId) &&
                    <li className="nav-item">
                        <a activeclassname={btnActive} className={btnActive == filter.id ? "nav-link button-list btnActive" : "nav-link button-list"} aria-current="page" title={filter.name} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title={filter.name}>
                            <i className="fa fa-filter"></i>
                        </a>
                    </li>
                }
                <li className="nav-item" onClick={() => activeBtnHandler(chart.id)}>
                    <a activeclassname={btnActive} className={btnActive == chart.id ? "nav-link button-list btnActive" : "nav-link button-list"} title={chart.name} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title={chart.name}>
                       {
                            textInfoBtn.find(item => item === chartDetail?.chartId) ?
                                <i class="fa-solid fa-text-height"></i>
                                : <i className="fa fa-chart-column"></i>
                        }
                    </a>
                </li>
                {
                    HeatMapBtn.find(item => item === chartDetail?.chartId) &&
                    <li className="nav-item">
                        <a activeclassname="active" className={btnActive == map.id ? "nav-link button-list btnActive" : "nav-link button-list"} title={map.name} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title={map.name}>
                            <i className="fa fa-location-dot"></i>
                        </a>
                    </li>
                }
                {
                    cardTableBtn.find(item => item === chartDetail?.chartId) &&
                    <li className="nav-item" onClick={() => activeBtnHandler(table.id)}>
                        <a activeclassname="active" className={btnActive == table.id ? "nav-link button-list btnActive" : "nav-link button-list"} title={table.name} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title={table.name}>
                            <i className="fa fa-table"></i>
                        </a>
                    </li>
                }
                {
                    btnActive !== table.id &&
                    !downloadBtn.find(item => item === chartDetail?.chartId) &&
                    <li className="nav-item" onClick={() => downloadPDF(chartDetail?.chartId)}>
                        <a activeclassname="active" className="nav-link button-list" title={download.name} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title={download.name}>

                            {loading ? <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div> : <i className="fa fa-download"></i>}
                        </a>
                    </li>
                }

            </ul>
        </>
    )
}