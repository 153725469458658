import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { LoadingSpinner } from '../../../../Components/LoadingSpinner';
import { useGetTopicQuery } from '../../../../Redux/Actions/sampleDescriptorSlice';
import { useSelector } from 'react-redux';
import { SDContainer } from './SDContainer';

export const OverLayModal = ({ show, handleClose }) => {

    const selectedTopic = useSelector((state) => state.selectedItems.selectedTopic);
    const token_id = useSelector((state) => state.selectedItems.token_id);
    const param = {
        topicId: selectedTopic?.topicId,
        token: token_id,
        year: selectedTopic?.year
    }
    const { data, error, isLoading } = useGetTopicQuery(param);

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="xl" //xl, lg
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Sample Descriptors </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        error ?
                            <p className="text-center fw-bold">Something went wrong try again</p>
                            :
                            isLoading ?
                                <LoadingSpinner /> :
                                data ?
                                    <SDContainer
                                        data={data}
                                    />
                                    :
                                    null
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}
