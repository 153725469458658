import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { StackOptionConfigWithXaxis } from './ChartConfig/StackChartConfigwithXaxis';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ChartFooter } from './ChartFooter';
import { MeanArea, subHArea } from './ChartConfig/ChartConst';
import { ToggleTableView } from '../Tables/BaseToggleTableView';
import { useSelector } from 'react-redux';
import "../Chart.css";
NoDataToDisplay(Highcharts);

export const ToggleOneRowStackGraphTable = ({ chartItem }) => {
    const toggleValue = useSelector((state) => state.selectedItems.selectedQToggle);
    return (
        <>
            {

                chartItem?.data.map((list) => (
                    <div className={`col-md-12 col-sm-12 col-12`} key={list.id}>
                        {
                            subHArea.find(item => item === chartItem?.chartId) &&
                            <div className="row chartMetaHead my-2">
                                <h4 className={`chart-title-1`}>{list?.name}</h4>
                                <div className='row'>
                                    {
                                        list?.metaData.length > 0 && 
                                        list.metaData.map((item) =>
                                            <div className="col-md-6 col-sm-12 col-12">
                                                <p>{item.categoryName} N={item?.payers} </p>
                                            </div>
                                        )}

                                </div>
                            </div>
                        }
                        <div key={toggleValue} className='row chartPanel'>
                            <div className={MeanArea.find(item => item === chartItem?.chartId) ? 'col-md-11 col-sm-11 col-12' : 'col-md-12 col-sm-12 col-12'} key={list.id}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={StackOptionConfigWithXaxis(list, chartItem, list.id)}
                                />
                            </div>
                            {
                                MeanArea.find(item => item === chartItem?.chartId) &&
                                <div className={`col-md-1 col-sm-1 col-12`} key={list.id}>
                                    <span><b>Mean</b></span>
                                    <p>{(list?.mean)?.toFixed(2)}</p>
                                </div>
                            }

                        </div>
                    </div>
                ))
            }

            <div className='row' >
                <ToggleTableView
                    chartItem={chartItem}
                />
            </div>
            <div className='col-md-12 col-sm-12'>
                <ChartFooter data={chartItem} />
            </div>
        </>

    )
}