import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const chartDataApi = createApi({
  reducerPath: 'chartData',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_MA_API}`,
    prepareHeaders: async (headers, { getState }) => {
      const access_token = getState().selectedItems.token_id;
      if (access_token) {
        headers.set('Access-Control-Allow-Origin', '*');
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getTopic: builder.query({
      query: ({ param, token_id }) => ({
        url: `charts/${param.chartId}/data?year=${param.year}${filterQueryparam(param?.selectedFilterItems)}`,
        headers: { 'Authorization': `Bearer ${token_id}` }
      }),
      keepUnusedDataFor: 3600,
    }),
  }),
})
export const { useGetTopicQuery } = chartDataApi;

const filterQueryparam = (selectedFilterItems) => {
  let queryString = '';
  if (selectedFilterItems)
    for (const [key, value] of Object.entries(selectedFilterItems)) {
      queryString += `&${key}=${value}`
    }
  return queryString;
}
