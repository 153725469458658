export const dateFormate = (param) => new Date(param).toLocaleDateString('en-US');

export const millionConvert = (param) => `${(param / 1000000).toFixed(1)} million`;
export const millionConvertValue = (param) => `${(param / 1000000).toFixed(1)}`;

export const subsciptionModule = {
    moduleId: "",
    name: "MMIT Subscriptions",
    description: "Find out about our other modules and offerings",
    btnLable: "Learn More",
    url: "https://www.mmitnetwork.com/"
}

export const removeSpace = (str) => str?.replace(/\s/g, '');

export const appConst = {
    errorText: "Something went wrong try again",
    chartFooter: {
        text: "*Percentages represent the portion of lives covered by payers.",
        survey: "Data collected"
    },
    topicAuthText: "No topics available to launch. You have not subscribed to any of the topics of this section."
}

export const descSort = (param) => param.slice().sort((a, b) => b?.id - a?.id) || [];

export const getYear = (param) => new Date(param).getFullYear();
export const getQuarter = (param) => Math.floor((new Date(param).getMonth() + 3) / 3);

export const cardButton = {
    filter: { id: 1, name: "Filter" },
    chart: { id: 2, name: "Chart" },
    map: { id: 3, name: "Map" },
    table: { id: 4, name: "Table" },
    download: { id: 5, name: "Download" },
}

export const baseRouteConfig = [
    {
        name: "Home",
        to: "/",
        redirect: "redirect"
    },
    {
        name: "AMT Module",
        to: "/topics",
        redirect: "redirect"
    }
]

export const topicListRoute = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 15, 19, 21, 22, 23, 24, 26]

export const reportTypeList = [3]

export const LOBConst = {
    1: "Commercial",
    2: "Medicare"
}

export const FirstLetterCapitalize = ([first, ...rest]) => {
    return first.toUpperCase() + rest.join('').toLowerCase();
}