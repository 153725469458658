import TableRowCell from "./TableRowCell";
import { getColor } from "./helper";
  
const TableRow = ({ columns, item, className, reportId }) => {
    return (
        <tr>
            {columns?.map((column, columnIndex) => (
                <TableRowCell
                    key={`table-row-cell-${columnIndex}`}
                    item={item}
                    column={column}
                    className={`${className} ${column['fieldName']==='col1' && getColor(reportId, 'bg')}`}
                />
            ))}
        </tr>
    );
}

export default TableRow;