import {
    ChartAlgin,
    defaultColumnChartAlign,
    chartCategoryHide,
} from './ChartConst';
import { displayPophoverTable } from "./ChartUtility";
import { defineColorCode, SERVEY_TYPE_PAYER } from './ChartColorConfig';
import { millionConvert } from '../../../../Constants/Constant';

export const OptionConfig = (chartItem, chartData, listId) => {
    const options = {
        chart: {
            type: 'column',//'bar',
            height: ChartAlgin?.[chartData?.chartId]?.height || defaultColumnChartAlign?.height,
            marginBottom: ChartAlgin?.[chartData.chartId]?.bottom || defaultColumnChartAlign?.bottom,
            marginTop: ChartAlgin?.[chartData.chartId]?.top || defaultColumnChartAlign?.top,
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        title: {
            text: '',
            align: 'center'
        },
        lang: {
            noData: 'No Data For Selected Filters'
        },
        noData: {
            style: {
                fontSize: '14px !important',
                fill: '#000 !important',
            }
        },
        xAxis: {
            visible: !chartCategoryHide?.some(item => item == chartData.chartId),
            categories: chartItem?.categories.map(({ name }) => name),
            title: {
                text: null
            },
            labels: {
                style: {
                    fontSize: '14px',
                    fontWeight: 800,
                    color: "#000"
                },
                formatter: function () {
                    const categoryFilter = chartItem?.categories?.filter(item => item.name == this.value)[0];
                    if (categoryFilter?.mean) {
                        return `${this.value} <i className="fa-solid fa-circle-chevron-left"></i> <br/><b>(Mean=${categoryFilter?.mean?.toFixed(1)})</b>`
                    } else if (categoryFilter?.count || categoryFilter?.count == 0) {
                        return `${this.value} (N=${categoryFilter.count})`
                    } else {
                        return this.value;
                    }
                },
            },
            gridLineWidth: 0,
            gridLineColor: "#000",
            minorGridLineWidth: 0,
            lineWidth: 0
        },
        colors: defineColorCode(chartItem?.surveyType?.replace(/\s+/g, ""), chartItem, chartData),
        yAxis: {
            visible: false,
            title: {
                text: null
            },
            tickPositioner() {
                const tickPositions = [];
                for (let i = 1; i < 11; i++) {
                    tickPositions.push(100 / 10 * i)
                }
                tickPositions.unshift(0)
                return tickPositions
            },
        },
        tooltip: {
            // valueSuffix: ' %',
            outside: true,
            useHTML: true,
            formatter: function () {
                if (chartItem?.surveyType === SERVEY_TYPE_PAYER) {
                    const chartCategaory = chartItem?.categories?.filter(item => item.name == this.key)[0];
                    const filterData = chartData?.chartId > 38 ?
                        chartItem?.series?.length > 1 ?
                            chartItem?.baseData?.rows?.filter(item => (item.OptionId == this.series.options.id && item.SeriesValue == chartCategaory.id) || (item.OptionId == chartCategaory.id && item.SeriesValue == this.series.options.id))
                            :
                            chartItem?.baseData?.rows?.filter(item => item.OptionId == chartCategaory.id || item.SeriesValue == chartCategaory.id)
                        : chartItem?.baseData?.data?.filter(item => item.filterColumn == chartCategaory.id)

                    const headerKey = chartItem?.baseData?.headers?.MouseoverOverlay?.[0] || "Organization";
                    const uniqueList = filterData.filter((obj, index) => {
                        return index === filterData.findIndex(o => obj?.[headerKey] === o?.[headerKey]);
                    }).slice(0, 10);
                    const displayResult = displayPophoverTable(uniqueList, headerKey)
                    return displayResult;
                } else {
                    return `${this.x}<br/> <span style="color:${this.series.color}">\u25CF</span> ${this.series.name}: <b> ${this.y.toFixed(1)}% </b>`;
                }
            }
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true,
                    // format: '{y}%'
                    formatter: function () {
                        return `${this.y.toFixed(1)}%`
                    },
                },
                events: {
                    legendItemClick: function () {
                        return false; // <== returning false will cancel the default action
                    }
                }
            },
            series: {
                cursor: 'pointer',
                groupPadding: 0.09,
                pointPadding: 0,
                point: {
                    events: {
                        click: function () {
                        }
                    }
                }

            }
        },
        legend: {
            enabled: true,
            align: 'center',
            verticalAlign: 'top',
            y: 10,
            floating: true,
            labelFormatter: function () {
                return `${this.name} <br/>
                ${this?.options?.metadata?.payers ?
                        `(N= ${this?.options?.metadata?.payers}) <br/>
                        ${this?.options?.metadata?.average ? `Average = ${this?.options?.metadata?.average?.toFixed(2)}` : ''}`
                        : `${this?.options?.metadata?.lives ? `(N= ${millionConvert(this?.options?.metadata?.lives)}) <br/>
                        ${this?.options?.metadata?.average ? `Average = ${this?.options?.metadata?.average?.toFixed(2)}` : ''}`
                            : ''}`
                    }`
            }
        },
        credits: {
            enabled: false
        },
        series: chartItem?.series
    }
    return options;
} 