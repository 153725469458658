import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

export default function BreadCrumb() {
    const routingMap = useSelector((state) => state.selectedItems.routingNav);

    return (
        <div role="presentation" onClick={handleClick} className="my-1">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {
                    routingMap.map(list => (
                        list.redirect === 'redirect' ?
                            <Link underline="hover" to={list.to} color="inherit" href="/">
                                {list.name}
                            </Link>
                            :
                            <Typography color="text.primary">{list.name}</Typography>
                    ))
                }
            </Breadcrumbs>
        </div>
    );
}