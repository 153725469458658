import React, { useState, useEffect, useCallback } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { OptionConfig } from './ChartConfig/TogglePlainSingleBar';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ChartFooter } from './ChartFooter';
import { useSelector } from 'react-redux';
import { millionConvert } from '../../../Constants/Constant';
import { CustomToolTip } from './ChartConfig/CustomToolTip';
import { PopOverOverlayTableView } from "../Tables/CommonTabularComponents/PopOverOverlayTableView";
import { payerPopOverlay, chartEvent, noPercentBar } from './ChartConfig/ChartConst';
import { OverlayTable } from '../Tables/OverlayTable';
import { ToggleOverlayTableView } from "../Tables/CommonTabularComponents/ToggleOverlayTableView";
import { OptionConfigNoPercent } from './ChartConfig/PlainSingleBarNoPercentage';
import "../Chart.css";
NoDataToDisplay(Highcharts);

export const TogglePlainSingleBarChart = ({ chartItem }) => {
    const [chart, setChart] = useState(null);
    const callback = useCallback((chart) => {
        setChart(chart);
    }, []);

    const [alignment, setAlignment] = React.useState();
    const [toggeleIndex, setToggeleIndex] = React.useState();

    const [show, setShow] = useState(false);
    const [dataFilter, setDataFilter] = useState([])
    const [selectedValue, setSelectedValue] = useState();
    const toggleValue = useSelector((state) => state.selectedItems.selectedQToggle);
    const selectedLOBValue = useSelector((state) => state.selectedItems.selectedLOBValue);
    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
        setShow(false)
    };

    const arrayUniqueByKey = [...new Map(chartItem?.data.map(item =>
        [item['groupId'], item])).values()];

    useEffect(() => {
        setAlignment(arrayUniqueByKey[0]?.groupId)
    }, [])

    const handleShow = (props, id, i) => {
        if (chartEvent[chartItem.chartId].find(item => item.toLowerCase() == props.toLowerCase())) {
            const chartCategaory = chartItem?.data[i]?.categories?.filter(item => item.name == props)[0];
            setToggeleIndex(i)
            setSelectedValue(chartCategaory?.id)
            setShow(true)
        } else {
            setShow(false)
        }
    };

    useEffect(() => {
        if (selectedValue) {
            const filterData = chartItem.chartId > 38 ?
                chartItem?.data[toggeleIndex]?.baseData?.rows?.filter(item => item.OptionId == selectedValue || item.SeriesValue == selectedValue)
                : chartItem?.data[toggeleIndex]?.baseData?.data?.filter(item => item.filterColumn == selectedValue)

            setDataFilter(filterData);
        }
    }, [chartItem, selectedValue])

    return (
        <>
            <div className="col-md-12 col-sm-12 col-12 d-flex mb-2 justify-content-center">
                <div className="btn-group" role="group" aria-label="Basic example">
                    {
                        arrayUniqueByKey?.map(item =>
                            <button
                                type="button"
                                className={alignment == item.groupId ? `btn btn-primary ${item?.surveyType?.replace(/\s+/g, "")}` : 'btn btn-light buttonToggleBg'}
                                onClick={(e) => handleAlignment(e, item.groupId)}
                            >
                                {item.groupName}
                            </button>
                        )
                    }
                </div>
            </div>
            {
                chartItem?.data.map((list, i) => (
                    list.groupId == alignment &&
                    <>
                        <div key={list.id} className="col-md-12 col-sm-6 col-12 mb-2">
                            <div className="row chartMetaHead">
                                <h4 className={`chart-title-1`}>{list?.name || list?.surveyType}</h4>
                                <div className='row'>
                                    {
                                        list?.metaData.length > 0 &&
                                        list.metaData.map((item) =>
                                            <>
                                                <div className="col-md-3 col-sm-12 col-12">
                                                    <p>{list?.surveyType} N={item?.payers} </p>
                                                </div>
                                                {
                                                    item?.lives > 0 &&
                                                    <div className="col-md-8 col-sm-12 col-12">
                                                        <p>{selectedLOBValue?.name} Lives N={millionConvert(item?.lives)} </p>
                                                    </div>
                                                }
                                            </>
                                        )}

                                </div>
                            </div>
                            <div key={toggleValue} className="row containerChartArea">
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={
                                        noPercentBar?.find(itemList=> itemList == chartItem?.chartId)?
                                        OptionConfigNoPercent(list, chartItem, list.id):
                                        OptionConfig(list, chartItem, list.id, handleShow, i)
                                    }
                                // callback= {payerPopOverlay?.find(item=> item == chartItem?.chartId) && callback}
                                />
                                {
                                    <CustomToolTip chart={chart} countIndex={i}>
                                        {(formatterContext) => {
                                            const { x, y, key } = formatterContext;
                                            const chartCategaory = chartItem?.data[0]?.categories.filter(item => item.name == key)[0];
                                            const filterData = chartItem.chartId > 38 ?
                                                chartItem?.data[0]?.baseData.rows.filter(item => item.OptionId == chartCategaory.id)
                                                : chartItem?.data[0]?.baseData.data.filter(item => item.filterColumn == chartCategaory.id)

                                            return (
                                                <div style={{ width: "350px", backgroundColor: "#FFF", zIndex: 1 }}>
                                                    {
                                                        chartItem.chartId > 38 ?
                                                            <PopOverOverlayTableView
                                                                dataFilter={filterData}
                                                                chartItem={chartItem}
                                                            />
                                                            :
                                                            <OverlayTable
                                                                dataFilter={filterData}
                                                                chartItem={chartItem}
                                                            />
                                                    }
                                                </div>
                                            );
                                        }}
                                    </CustomToolTip>
                                }
                            </div>
                        </div>
                        {
                            show &&
                            <div className='row' >
                                {
                                    chartItem.chartId > 38 ?
                                        <ToggleOverlayTableView
                                            dataFilter={dataFilter}
                                            chartItem={chartItem}
                                            toggeleIndex={toggeleIndex}
                                        />
                                        :
                                        <OverlayTable
                                            dataFilter={dataFilter}
                                            chartItem={chartItem}
                                        />
                                }

                            </div>
                        }
                    </>
                ))
            }
            <div className='col-md-12 col-sm-12'>
                <ChartFooter data={chartItem} />
            </div>
        </>

    )
}