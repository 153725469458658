import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ChartViewContainer } from './TabContainer/CardContainer';
import { TopicHeader } from '../Topics/TopicHeader';
import { FilterPanelContainer } from './FilterPanel/FilterPanelContainer';
import Button from 'react-bootstrap/Button';
import { useGetTopicQuery } from '../../Redux/Actions/subTopicChartSlice';
import { QuestionPanel } from './QuestionPanel';
import { useSelector, useDispatch } from 'react-redux';
import { getSubTopicTab } from '../../Services/Modules';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { selectQToggle } from '../../Redux/Actions/selecetdItemsSlice';
import BreadCrumb from '../../Components/BreadCrumbs';
import { OverLayModal } from './Tables/CustomTable/OverLayModal';
import "./Chart.css"

export const TopicTab = () => {
    const [key, setKey] = useState();
    const [selectedFilterItems, setSelectedFilterItems] = useState({});
    const [show, setShow] = useState(false);
    const [subTopicRes, setSubTopicRes] = useState();
    const [activeTab, setActiveTab] = useState();
    const [qToggle, setQToggle] = useState(false);
    const [showSD, setShowSD] = useState(false);

    const dispatch = useDispatch();
    const selectedTopic = useSelector((state) => state.selectedItems.selectedTopic);
    const token_id = useSelector((state) => state.selectedItems.token_id)

    const param = {
        topicId: selectedTopic.topicId,
        year: selectedTopic.topicId == 2 ? 2023 : selectedTopic.year,
        subTopicId: key,
        token: token_id
    }

    const { data, error, isLoading } = useGetTopicQuery(param);

    const questionlist = data?.some(item => item?.sortIndex == undefined) ?
        data?.slice().sort((a, b) => a?.chartId - b?.chartId) || []
        : data?.slice().sort((a, b) => a?.sortIndex - b?.sortIndex) || []

    useEffect(() => {
        const sortQuestionList = data?.some(item => item?.sortIndex == undefined) ?
            data?.slice().sort((a, b) => a?.chartId - b?.chartId)[0] || []
            : data?.slice().sort((a, b) => a?.sortIndex - b?.sortIndex)[0] || []
        setActiveTab(sortQuestionList?.chartId)
    }, [data])
    useEffect(() => {
        getSubTopicTab(selectedTopic.topicId, token_id).then(resp => {
            const test = resp?.length > 0 ? resp[0]?.subTopicId : resp?.subTopicId;
            setKey(test)
            setSubTopicRes(resp);
        })
    }, [])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDesciptorShow = () => setShowSD(true)
    const handleCloseDesciptorShow = () => setShowSD(false)

    const questionToggle = (prop) => {
        dispatch(selectQToggle(prop));
        setQToggle(prop);
    }
    return (
        <div className="container-fluid containerFixed">
            <div style={{ width: "94%" }}>
                <TopicHeader />
                <div className='row bg-white border-bottom align-items-center'>
                    <BreadCrumb />
                </div>

                <div className='row mb-5 copayTab'>
                    {
                        qToggle &&
                        <div className='question_toggle' onClick={() => questionToggle(false)} title="click to maximize the panel">
                            <p className="questionLabel" role="button">
                                <span className="my-3">
                                    <i class="fa-solid fa-circle-chevron-right"></i>
                                </span>
                                Survey Topics
                            </p>
                        </div>
                    }

                    <QuestionPanel
                        isLoading={isLoading}
                        questionlist={questionlist}
                        error={error}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        questionToggle={questionToggle}
                        qToggle={qToggle}
                        key={key}
                    />

                    <div className={qToggle ? "chartFullArea col-md-11 col-lg-12" : "col-sm-8 col-md-8 col-lg-9"}>
                        <div className="float-end mt-2 filterBtn">
                            <Button variant="primary" onClick={handleDesciptorShow} className="me-2">
                                Sample Descriptors
                            </Button>
                            {
                                showSD &&
                                <OverLayModal                                    
                                    handleClose={handleCloseDesciptorShow}
                                    show={showSD}
                                    topicId={selectedTopic?.topicId}
                                />
                            }
                            <Button variant="primary" onClick={handleShow} className="me-2">
                                Filter
                            </Button>
                            {
                                key &&
                                <FilterPanelContainer
                                    handleClose={handleClose}
                                    show={show}
                                    setSelectedFilterItems={setSelectedFilterItems}
                                    subTopickey={key}
                                />
                            }

                        </div>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="py-2 border-0"
                        >
                            {
                                subTopicRes?.map((tabItem) => (
                                    <Tab
                                        className="cardContainerSize"
                                        data-mdb-perfect-scrollbar='true'
                                        eventKey={tabItem.subTopicId}
                                        title={tabItem.name}
                                        data-spy="scroll"
                                        data-target="#list-example"
                                        data-offset="0"
                                        key={tabItem.subTopicId}
                                    >
                                        {
                                            error ?
                                                <p className="text-warning">Something went wrong try again</p>
                                                :
                                                isLoading ?
                                                    <LoadingSpinner /> :
                                                    key == tabItem.subTopicId ?
                                                        <ChartViewContainer
                                                            selectedFilterItems={selectedFilterItems}
                                                            subTopicChartList={questionlist}
                                                            subTopickey={key}
                                                        />
                                                        : null
                                        }

                                    </Tab>
                                ))
                            }
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}