import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { selectTopic, updateRoutingNav } from '../../Redux/Actions/selecetdItemsSlice';
import { getYear, baseRouteConfig, topicListRoute } from '../../Constants/Constant';
import "./topic.css";

export const TopicItem = ({ list }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = (list) => {
        const selectedItem = {
            ...list,
            year: getYear(list?.completedDate)
        }
        const param = [...baseRouteConfig,
        {
            name: `${list.name}  (${list.year})`,
            to: "/topics/reports",
            redirect: ""
        }]
        dispatch(selectTopic(selectedItem));
        dispatch(updateRoutingNav(param));

        topicListRoute.find((item) => item === list.topicId) &&
            navigate('/topics/reports');

    };
    return (
        <>
            {
                list.isAuthorized &&
                <div className='mr-2 mt-2 mx-2 d-inline-flex'>
                    <div
                        className="card topicCard my-auto shadow-sm bg-white rounded h-100 align-text-bottom"
                        onClick={() => { handleClick(list) }}
                        style={{ width: "20rem", minHeight: "6rem" }}
                    >
                        <div className="card-body d-flex align-items-center">
                            <img src={`assets/images/topics/topicId-${list.topicId || 5}.svg`} width={50} height={50} className="me-3" alt="topic icon" />
                            <h6 className="card-subtitle mb-2">{list.name}</h6>
                        </div>
                    </div>
                </div>
            }
        </>
    )

}