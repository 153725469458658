
export const getRowData = (data) => {
    const obj = data?.reduce((accData, currentData) => {
        const topicName = currentData?.['indexName'] ? `${currentData?.['indexName']}_${currentData?.['topicName']}_(N = ${currentData?.['metadata']?.['payers']})` : `${currentData?.['topicName']}_(N = ${currentData?.['metadata']?.['payers']})`;
        const descriptor = currentData?.['reportId'];
        if (accData[descriptor]) {
            accData[descriptor]?.['headerName']?.push(topicName);
            const index = accData[descriptor]?.['headerName']?.length;

            [...currentData?.['sections']]?.sort((a, b) => a?.['id'] - b?.['id'])?.forEach(section => {
                if (accData[descriptor]?.['rowData']?.[section?.['name']]) {
                    section?.['items']?.reduce((accItem, currentItem) => {
                        const val = accData[descriptor]?.['rowData']?.[section?.['name']]?.find(el => el?.['id'] === currentItem?.['id']);

                        if (val) {
                            val[`col${index}`] = getFormatedValue(currentItem['value'], section?.['unit']);
                            return [...accItem, val];
                        } else {
                            return [...accItem, {
                                'id': currentItem?.['id'],
                                'col1': currentItem?.['name'],
                                [`col${index}`]: getFormatedValue(currentItem['value'], section?.['unit'])
                            }
                            ]
                        }
                    }, [])
                } else accData[descriptor]['rowData'][section?.['name']] = section?.["items"]?.sort((a, b) => a?.['id'] - b?.['id'])?.reduce((accItem, currentItem) => {
                    return [...accItem, {
                        'id': currentItem?.['id'],
                        'col1': currentItem?.['name'],
                        [`col${index}`]: getFormatedValue(currentItem?.['value'], section?.['unit'])
                    }];
                }, []);
            })
        } else accData[descriptor] = {
            'rowData': [...currentData?.["sections"]]?.sort((a, b) => a?.['id'] - b?.['id'])?.reduce((accSection, currentSection) => {
                const itemArray = [...currentSection?.["items"]]?.sort((a, b) => a?.['id'] - b?.['id'])?.reduce((accItem, currentItem) => {
                    return [...accItem, {
                        'id': currentItem?.['id'],
                        'col1': currentItem?.['name'],
                        'col2': getFormatedValue(currentItem?.['value'], currentSection?.['unit'])
                    }];
                }, []);

                accSection[currentSection?.['name']] = itemArray;
                return accSection;
            }, {}),
            'headerName': [ [...currentData?.["sections"]]?.sort((a,b)=> a?.['id']-b?.['id'])[0]?.['name'], topicName ]
        };

        return accData;
    }, {});

    return obj;
}

export const getColDef = (data) => {
    const colHeader = data?.map((el, index) => {
        return {
            fieldName: `col${index + 1}`,
            headerName: el,
            width: index === 0 ? '40vw' : '40vw'
        }
    })

    return colHeader;
}

export const getReportHeader = (reportId) => {
    let header;

    switch (reportId) {
        case 1:
            header = 'Payer - Commercial';
            break;
        case 2:
            header = 'Payer - Medicare';
            break;
        case 3:
            header = 'Physician';
            break;
        case 4:
            header = 'Practice Manager';
            break;
        default:
            header = '';
            break;
    }

    return header;
}

export const getColor = (reportId, classType) => {
    let className;

    switch (reportId) {
        case 1:
            className = `${classType}_payer_commercial`;
            break;
        case 2:
            className = `${classType}_payer_medicare`;
            break;
        case 3:
            className = `${classType}_physician`;
            break;
        case 4:
            className = `${classType}_practiceManager`;
            break;
        default:
            className = '';
            break;
    }

    return className;
}

const getFormatedValue = (value, unit) => {
    if (unit === 'Percentage') return `${value}%`;
    else if (unit === 'Number') return value.toString().length > 6 ? `${(value / 1.0e+6).toFixed(1)} M` : value
    else return value
}

export const getTabColor = (reportId) => {
    let sdTabColor;
    switch (reportId) {
        case 1:
            sdTabColor = 'Payers';
            break;
        case 2:
            sdTabColor = 'Payers';
            break;
        case 3:
            sdTabColor = 'Physicians';
            break;
        case 4:
            sdTabColor = 'PracticeManagers';
            break;
        default:
            sdTabColor = '';
            break;
    }

    return sdTabColor;
}