import { appConst, dateFormate, millionConvert } from '../../../Constants/Constant';
import { PayerFootnote, PayerVerbFootnote } from './ChartConfig/ChartConst';
import { useSelector } from 'react-redux';

export const ChartFooter = ({ data }) => {
    const selectedLOBValue = useSelector((state) => state.selectedItems.selectedLOBValue);

    return (
        <div className="row alert mt-2 copay-alert alert-secondary" role="alert">
            <div className="text-left lh-1" style={{"width":"auto"}}>
                {
                    PayerVerbFootnote.find(item => item === data?.chartId) &&
                    <>{appConst.chartFooter.text} <br/></>
                }
                {
                    PayerFootnote.find(item => item === data?.chartId) &&
                    <>{selectedLOBValue?.name}:
                        {
                            data?.data[0]?.metaData?.map(item => (
                                <span key={item.categoryId}>
                                    Payers N = <b>{item?.payers}</b>
                                    {
                                        item.lives > 0 &&
                                        <>
                                            , Lives =<b>{millionConvert(item.lives)}</b>
                                        </>
                                    }
                                </span>
                            ))
                        }
                        <br/>
                    </>
                }
                <>{appConst.chartFooter.survey}: <b>{dateFormate(data?.surveyPeriod?.startDate)}</b> - <b>{dateFormate(data?.surveyPeriod?.endDate)}</b></>
            </div>
        </div>
    )
}