import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { StackOptionConfig } from './ChartConfig/StackChartConfig';
import { millionConvert } from '../../../Constants/Constant';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ChartFooter } from './ChartFooter';
import { useSelector } from 'react-redux';
import "../Chart.css";
NoDataToDisplay(Highcharts);

export const OneRowStackGraph = ({ chartItem }) => {
    const toggleValue = useSelector((state) => state.selectedItems.selectedQToggle);
    const selectedLOBValue = useSelector((state) => state.selectedItems.selectedLOBValue);
    return (
        <>
            {
                chartItem?.data.map((list) => (
                    <div className={`col-md-12 col-sm-12 col-12`} key={list.id}>
                        {
                            list?.metaData.length > 0 &&
                            <div className="row chartMetaHead my-2">
                                <h4 className={`chart-title-1`}>{list?.name || list?.surveyType}</h4>
                                <div className='row'>
                                    <div className="col-md-3 col-sm-12 col-12">
                                        <p>{list?.surveyType} N={list?.metaData[0]?.payers} </p>
                                    </div>
                                    <div className="col-md-8 col-sm-12 col-12">
                                        <p>{selectedLOBValue?.name} Lives N={millionConvert(list?.metaData[0]?.lives)} </p>
                                    </div>
                                </div>
                            </div>
                        }

                        <div key={toggleValue} className='row  chartPanel'>
                            <div className={'col-md-11 col-sm-11 col-12'}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={StackOptionConfig(list, chartItem, list.id)}
                                />
                            </div>
                        </div>
                    </div>
                ))
            }
            <div className='col-md-12 col-sm-12'>
                <ChartFooter data={chartItem} />
            </div>
        </>

    )
}