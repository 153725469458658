import TableRow from "./TableRow";
  
const TableBody = ({ data, columns, reportId }) => {
    return (
        <>
            {
               data && Object.keys(data)?.map((section, index) => (
                    <tbody key={index}>
                        { index !==0 && <TableRow columns={columns} item={{'col1': section}} className='sectionHeader' reportId={reportId} /> }
                        {
                            data[section]?.map((item, itemIndex) => <TableRow key={`table-body-${itemIndex}`} columns={columns} item={item} className='sectionContent' />)
                        }
                    </tbody>
                ))
            }
        </>
    );
}

export default TableBody;