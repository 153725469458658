import React from 'react';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './Redux/Store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

let persistor = persistStore(store);

export const Main = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}
