import React, { useEffect } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport
} from '@mui/x-data-grid';
import { columnChartHeaderDef } from "../ColumnConfig";
import "../style.css";

export const ToggleSingleOverlayTableView = ({ chartItem, dataFilter }) => {
    const [alignment, setAlignment] = React.useState();

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const arrayUniqueByKey = [...new Map(chartItem?.data?.map(item =>
        [item['groupId'], item])).values()];

    useEffect(() => {
        setAlignment(arrayUniqueByKey[0]?.groupId)
    }, [])

    const filterRowTable = (rowList, filterParam) => {
        const filterData = rowList?.filter(item => {
            if (filterParam?.length === 1) {
                return (
                    item[`${filterParam[0]}`] !== '' &&
                    item[`${filterParam[0]}`] !== null
                )
            } else {
                return item;
            }
        })
        return filterData;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer >
                <GridToolbarExport
                    printOption={{ hideFooter: true, hideToolbar: true }}
                    csvOptions={{
                        fileName: chartItem?.title
                    }} />
            </GridToolbarContainer>
        );
    }
    return (
        chartItem?.data?.map((list) => (
            list.groupId == alignment &&
            <>
                <div key={list.id} className="col-md-12 col-sm-12 col-12 mb-2">
                    <div className="row">
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                className={`secondTable table_${list?.surveyType?.replace(/\s+/g, "")}`}
                                rows={
                                    filterRowTable(dataFilter, list?.baseData?.headers?.Overlay)
                                }
                                getRowId={() => Math.floor(Math.random() * 100000000)}
                                columns={columnChartHeaderDef(list?.baseData?.headers?.Overlay, 3) || []}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 10 },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 20, 30, 50]}
                                hideFooterSelectedRowCount
                                getRowHeight={() => 'auto'}
                                sx={{
                                    '& .MuiDataGrid-cell': {
                                        py: '8px',
                                    },
                                }}
                                slots={{ toolbar: CustomToolbar }}
                                slotProps={{ toolbar: { printOption: { disableToolbarButton: true } } }}
                            />
                        </div>
                    </div>
                </div>
            </>
        ))
    )
}