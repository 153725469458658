
import { FirstLetterCapitalize } from "../../../../Constants/Constant";

export const displayPophoverTable = ( paramList, headerKey) => {

    let result = ''
    result = '<div class="popHoverTable">';
    result += '<table>';
    result += '<tr class="popHoverHeader"><td>' + FirstLetterCapitalize(headerKey) + '</td></tr>';
    paramList?.length > 0 ?
    paramList?.forEach(p => {
        result += '<tr><td>' + p?.[headerKey] + ' </td>' +
            '</tr>';
    })
    : result += '<tr><td style="padding:20px; text-align:center"> No Rows </td></tr>' 

    result += '</table>';
    result += '</div>';

    return result;
}