import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { OptionConfig } from './ChartConfig/VerticlePlainSingleBar';
import { OptionConfigNoPercent } from './ChartConfig/PlainSingleBarNoPercentage';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ChartFooter } from './ChartFooter';
import { useSelector } from 'react-redux';
import { noPercentBar } from './ChartConfig/ChartConst';
import { millionConvert, LOBConst } from '../../../Constants/Constant';

import "../Chart.css";
NoDataToDisplay(Highcharts);

export const VerticleTwoViewPlainSingleBarChart = ({ chartItem }) => {
    const toggleValue = useSelector((state) => state.selectedItems.selectedQToggle);
    const selectedLOBValue = useSelector((state) => state.selectedItems.selectedLOBValue);

    return (
        <>
                {
                    chartItem?.data.map((list, i) => (
                        <>
                            <div className={`chartBorder col-md-6 col-sm-6 col-12`} key={list.id}>
                                {
                                    <div className="row chartMetaHeadTwoView">
                                        <h4 className={`chart-title-1`}>{list?.name || list?.surveyType}</h4>
                                        <div className='row'>
                                            {
                                                list?.metaData.length > 0 &&
                                                list.metaData.map((item) =>
                                                    <>
                                                        <div className="col-md-3 col-sm-12 col-12">
                                                            <p>{list?.surveyType} N={item?.payers} </p>
                                                        </div>
                                                        {
                                                            item?.lives > 0 &&
                                                            <div className="col-md-8 col-sm-12 col-12">
                                                                <p>{selectedLOBValue?.name || LOBConst[item?.categoryId]} Lives N={millionConvert(item?.lives)} </p>
                                                            </div>
                                                        }

                                                    </>
                                                )}

                                        </div>
                                    </div>
                                }
                                <div className="row" key={toggleValue}>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={
                                            noPercentBar?.find(itemList => itemList == chartItem?.chartId) ?
                                                OptionConfigNoPercent(list, chartItem, list.id) :
                                                OptionConfig(list, chartItem, list.id)
                                        }
                                    />

                                </div>
                            </div>
                        </>
                    ))
                }
            
            <div className='col-md-12 col-sm-12'>
                <ChartFooter data={chartItem} />
            </div>
        </>

    )
}