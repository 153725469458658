import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { StackChartConfigwithLive } from './ChartConfig/StackChartConfigwithLive';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ChartFooter } from './ChartFooter';
import { OverlayTable } from '../Tables/OverlayTable';
import { chartEvent, subHArea } from './ChartConfig/ChartConst';
import { millionConvert } from '../../../Constants/Constant';
import { useSelector } from 'react-redux';
import "../Chart.css";
NoDataToDisplay(Highcharts);

export const OneRowStackGraphLive = ({ chartItem }) => {
    const [show, setShow] = useState(false);
    const [dataFilter, setDataFilter] = useState([])
    const [selectedValue, setSelectedValue] = useState();
    const toggleValue = useSelector((state) => state.selectedItems.selectedQToggle);
    const selectedLOBValue = useSelector((state) => state.selectedItems.selectedLOBValue);
    
    const handleShow = (props, id) => {
        if (chartEvent[chartItem.chartId].find(item => item == props)) {
            const filterData = chartItem?.data[0]?.baseData.data.filter(item => item.filterColumn == props)
            setDataFilter(filterData);
            setSelectedValue(props);
            setShow(true);
        } else {
            setShow(false)
        }
    };

    useEffect(() => {
        if (selectedValue) {
            const filterData = chartItem?.data[0]?.baseData.data.filter(item => item.filterColumn == selectedValue)
            setDataFilter(filterData);
        }
    }, [chartItem])
    return (
        <>

            {
                chartItem?.data.map((list) => (
                    <div className={`col-md-12 col-sm-12 col-12`} key={list.id}>
                        {
                            subHArea.find(item => item === chartItem?.chartId) &&
                            <div className="row chartMetaHead my-2">
                                <h4 className={`chart-title-1`}>{list?.name || list?.surveyType}</h4>
                                <div className='row'>
                                    {
                                        list?.metaData.length > 0 &&
                                        list.metaData.map((item, index) =>
                                            <>
                                                <div className="col-md-3 col-sm-12 col-12">
                                                    <p>{list?.surveyType} N={item?.payers} </p>
                                                </div>
                                                {
                                                    item?.lives > 0 &&
                                                    <div className="col-md-8 col-sm-12 col-12">
                                                        <p>{selectedLOBValue?.name} Lives N={millionConvert(item?.lives)} </p>
                                                    </div>
                                                }

                                            </>
                                        )}

                                </div>
                            </div>
                        }
                        <div key={toggleValue} className='row chartPanel'>
                            <div className={'col-md-12 col-sm-12 col-12'} key={list.id}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={StackChartConfigwithLive(list, chartItem, list.id, handleShow)}
                                />
                            </div>
                        </div>
                    </div>
                ))
            }
            {
                show &&
                <div className='row' >
                    <OverlayTable
                        dataFilter={dataFilter}
                        chartItem={chartItem}
                    />
                </div>
            }
            <div className='col-md-12 col-sm-12'>
                <ChartFooter data={chartItem} />
            </div>
        </>

    )
}