export const defaultChartColor = [
    "#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9",
    "#4f87af", "#1f425b",
    "#8095a3", "#8daabd", "#5b8cad", "#6aa4cb", "#2775a9", "#0b6cad"];
export const finalColor = [
    "#39576C", "#78c6cd", "#4F84AA",
    "#6ad0e3",
    "#73AAD1", //old
    "#6ad0e3", "#7fcad9", "#87c1e9",
    "#A6C9E2", "#cde9ec",
    "#bed5de", "#D9E8F2",
]

export const payerColorConst = [
    "#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9",
    "#4f87af", "#1f425b",
]
export const physicianColorConst = [
    "#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1", "#998bb1"
]
export const practiceMangerColorConst = [
    "#464F2F", "#798F44", "#ACCE5A", "#C5DD8C",
    "#d7ee9f", "#9faf79", "#d3ee91", "#DFECBF",
]
export const finalPM = [
    "#464F2F",
    "#a4d139", "#798F44", "#b5df51", "#8aaa3c",
    "#ACCE5A", "#C5DD8C",
    "#d7ee9f", "#d3ee91", "#DFECBF",
]

export const finalPY = [
    "#250162", "#5322a7", "#8351d9", "#7a4cc9",
    "#9b6eea", "#a982da", "#b086e5", "#cab4e7",
    "#cbb5f1", "#998bb1", "#a887e0",
]


export const testPayer = [
    "#213a43",
    // "#39576C",
    "#284d59", "#2f6170", "#367588",
    "#5b8b9b", "#7ca1ae", "#9cb8c2", "#bdcfd6", "#dee7ea",
    "#417181", "#496e7a", "#2e6e81", "#367588", "#3e7c8f",
]
export const testPhysician = [
    "#33155e", "#40177e", "#4e18a0", "#5b17c4",
    "#7e44cf", "#9b69d9", "#b68ee3", "#d0b3ed",
    "#e8d9f6"
]
export const testPM = [
    "#233221", "#31492f", "#4e7b4b", "#5d955a",
    "#63905f", "#688c64",
    "#78a674", "#93b88e", "#adc9aa"
]

export const defineColorCode = (param, list, chartData) => {
    switch (param) {
        case "Payers": return list?.series?.length < 4 ? lessThenTwoPayer : testPayer;
        
        case "Physicians":  return list?.series?.length < 3 ? lessThenTwoPhysician : testPhysician;
        
        case "Oncologists": return list?.series?.length < 3 ? lessThenTwoPhysician : testPhysician;
        
        case "PracticeManagers": return list?.series?.length < 3 ? lessThenTwoPM : testPM;
        
    }
}

export const lessThenTwoPayer = ["#2f6170", "#5b8b9b", "#9cb8c2"];
export const lessThenTwoPhysician = ["#40177e", "#7e44cf"];
export const lessThenTwoPM = ["#31492f", "#78a674"];

export const SERVEY_TYPE_PAYER = "Payers"