  import { useEffect, useState, useRef } from "react";
  import ReactDOM from "react-dom";
  
  const generateTooltipId = (chartId, countIndex) =>
    `highcharts-custom-tooltip-${countIndex}-${chartId}`;

  export const CustomToolTip = ({ chart, children, countIndex }) => {
    const isInit = useRef(false);
    const [context, setContext] = useState(
      null
    );
  
    useEffect(() => {
      if (chart) {
        const formatter = function () {
          // Ensures that tooltip DOM container is rendered before React portal is created.
          if (!isInit.current) {
            isInit.current = true;
  
            // TODO: Is there a better way to create tooltip DOM container?
            // chart.tooltip.refresh.apply(chart.tooltip, [this.point]);
            // chart.tooltip.hide(0);
            // chart.tooltip.refresh([chart.series[0].points[0]]);

            chart.tooltip.refresh.apply(chart.tooltip, [this.point]);
          }
           setContext(this);
          return `<div id="${generateTooltipId(chart.index, countIndex)}"></div>`;
        };
        
        if(chart?.tooltip){
            chart.update({
                tooltip: {
                  formatter,                  
                  useHTML: true,
                  style: {
                    "vertical-algin": "left",
                    top: "0px",
                    left: "0px"
                  }
                }
              });
        } 
      }
    }, [chart]);
  
    useEffect(() => {
      if (context) {
        const tooltip= context?.series?.chart?.tooltip;
        const textEl = tooltip?.label?.text?.element;
  
        tooltip?.label?.box?.attr({
          height: textEl?.offsetHeight + 12,
          width: textEl?.offsetWidth + 16
        });
  
        tooltip?.label?.attr({
          height: 0
        });
  
        tooltip?.label?.text.css({
          top: "8px"
        });
      }
    }, [context]);

    const node = chart && document.getElementById(generateTooltipId(chart.index, countIndex));
  
    return node && context
      ? ReactDOM.createPortal(children(context), node)
      : null;
  };
  