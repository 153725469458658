import React from 'react';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Chart.css";

export const QuestionPanel = ({
    questionlist,
    isLoading,
    error,
    activeTab,
    setActiveTab,
    questionToggle,
    qToggle,
    key
}) => {   

    return (
        <>
            {
                !qToggle &&
                <div id="Ques_list" className='col-0 col-sm-4 col-md-3 col-lg-3 d-none d-sm-block bg-white'>
                    <h6 className='text-center py-2 questionLabel' role="button" onClick={() => questionToggle(true)} title="click to minimize the panel">
                        Survey Topics
                        <span className="mx-3 float-end">
                            <i class="fa-solid fa-circle-chevron-left"></i>
                        </span>
                    </h6>
                    <div className='list-group cardContainerSize'>
                        {
                           key && error ?
                                <p className="text-warning">Something went wrong try again</p>
                                :
                                isLoading ?
                                    <LoadingSpinner /> :
                                    questionlist ?
                                        questionlist?.map((qlist) => (
                                            <div key={qlist.chartId}>
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={10}
                                                    overlay={<Tooltip id="tooltip1">{qlist.title}</Tooltip>}

                                                >
                                                    <a className={`list-group-item list-group-item-action py-2 ${(qlist.chartId == activeTab) ? 'active' : ''}`} onClick={() => {
                                                        setActiveTab(qlist.chartId)
                                                    }}
                                                        href={`#questionSection-${qlist.chartId}`}
                                                    >
                                                        {qlist.name}
                                                    </a>
                                                </OverlayTrigger>
                                            </div>
                                        ))
                                        :
                                        null
                        }

                    </div>
                </div>
            }

        </>
    );
}
