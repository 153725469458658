import { CHARTS_MAPPING } from '../../../Utitlities/chartsMapping';
import { TABLE_MAPPING } from '../../../Utitlities/tableMapping';


export const GetPlot = (list, data) => {
    const chartId = data.chartId;
    const findCards = CHARTS_MAPPING.find(p => p.subtopic == list.subTopicId);
    if (findCards) {
        const topicCards = findCards.cards;
        const cardToLoad = topicCards.find(p => p.chartId == chartId);
        if (cardToLoad && cardToLoad.component) {
            const ComponentName = cardToLoad.component;
            return <ComponentName chartItem={data} />;
        }
    } else {
        return <>No Cards Found</>
    }
}

export const GetTable = (list, data) => {
    const chartId = data.chartId;
    const findCards = TABLE_MAPPING.find(p => p.subtopic == list.subTopicId);
    if (findCards) {
        const topicCards = findCards.cards;
        const cardToLoad = topicCards.find(p => p.chartId == chartId);
        if (cardToLoad && cardToLoad.component) {
            const ComponentName = cardToLoad.component;
            return <ComponentName chartItem={data} />;
        }
    } else {
        return <>No Cards Found</>
    }
}

