import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const subTopicApi = createApi({
  reducerPath: 'SubTopic',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_MA_API}`,
    prepareHeaders: async (headers, { getState }) => {
      const access_token = getState().selectedItems.token_id;
      if (access_token) {
        headers.set('Access-Control-Allow-Origin', '*');
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getTopic: builder.query({
      query: ({ topicId, subTopicId, year, token }) => ({
        url: subTopicId && `topics/${topicId}/${subTopicId}/charts?year=${year}`,
        headers: { 'Authorization': `Bearer ${token}` }
      }),
      keepUnusedDataFor: 3600,
    }),
  }),
})
export const { useGetTopicQuery } = subTopicApi

