import React, { useState } from 'react';
import { ChartCard } from './ChartCard';
import { ChartCardButtons } from './ChartCardButtons';
import { customLabel } from '../../../Constants/LocalResource';
import '../Chart.css';

export const ChartView = ({ selectedFilterItems, listItem }) => {
    const [activeArea, setActiveArea] = useState(2);
    const activeHandler = (props) => {
        setActiveArea(props);
    }
    return (
        <>
            <div className="col-sm-12 col-md-12 col-10 mb-3" >
                <div className="card shadow-sm">
                    <div className="card-body" id={`chartCardPlace-${listItem?.chartId}`}>
                        <div className='row pb-3'>
                            <div className='col-md-8 col-md-8' id={`questionSection-${listItem?.chartId}`}>
                                <h5 className="card-title mt-3 copayHeaderText">{listItem?.title}*
                                    {
                                        <i><b><br />{customLabel[listItem?.chartId]?.subTitle}</b></i>
                                    }
                                </h5>
                            </div>
                            <div className='col-md-4 col-md-4'>
                                <ChartCardButtons activeHandler={activeHandler} chartDetail={listItem} />
                            </div>
                        </div>
                        <ChartCard list={listItem} selectedFilterItems={selectedFilterItems} activeArea={activeArea} />
                    </div>
                </div>
            </div>
        </>
    )
}