
export const tableHeadConst = {
    45:{
        3:['column3'],
    },
    56:{
        3:['column2'],
    },
}

export const tableColumnFormate = [45, 56]