import axios from "axios";

export const getModuleList = () => {
    return axios.get(`${process.env.REACT_APP_MA_API}modules`, {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    }
    ).then(response => {
        return response.data
    })
}

export const getTopicList = () => {
    return axios.get(`${process.env.REACT_APP_MA_API}modules/1/topics`, {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    }
    ).then(response => {
        return response.data
    })
}

export const getSubTopicTab = async (topicId, token_id) => {
    return axios.get(`${process.env.REACT_APP_MA_API}topics/${topicId}/subtopics`, {
        'headers': {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token_id}`,
            'Content-Type': 'application/json'
        }
    }
    ).then(response => {
        return response.data
    })
}