import { getColor } from "./helper";

const TableHeader = ({ columns, reportId }) => {
    return (
        <tr>
            {columns?.map((column, columnIndex) => {
                const cols = column?.headerName?.split('_');
                return (
                    <th
                        key={`table-head-cell-${columnIndex}`}
                        style={{ width: column.width}}
                        className={`tableHeader ${columnIndex === 0 ? getColor(reportId, 'bg') : getColor(reportId, 'color')}`}
                    >
                        <div>
                            {cols?.map(((header, index) => <span key={index} className={`${index === cols.length - 1 && 'color_black'}`}>{header}</span>))}
                        </div>
                    </th>
                )
            })}
        </tr>
    );
}   

export default TableHeader;