import React, { useEffect } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport
} from '@mui/x-data-grid';
import { millionConvert } from '../../../Constants/Constant';
import { ChartFooter } from '../Charts/ChartFooter';
import { columnDef } from "./ColumnConfig";
import "./style.css";

export const ToggleTableView = ({ chartItem }) => {
    const [alignment, setAlignment] = React.useState();

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const arrayUniqueByKey = [...new Map(chartItem?.data?.map(item =>
        [item['groupId'], item])).values()];

    useEffect(() => {
        setAlignment(arrayUniqueByKey[0]?.groupId)
    }, [])

    function CustomToolbar() {
        return (
            <GridToolbarContainer >
                <GridToolbarExport
                    printOption={{ hideFooter: true, hideToolbar: true }}
                    csvOptions={{
                        fileName: chartItem?.title
                    }} />
            </GridToolbarContainer>
        );
    }
    return (
        <>
            <div className="col-md-12 col-sm-12 col-12 d-flex mb-2 justify-content-center">
                <div className="btn-group" role="group" aria-label="Basic example">
                    {
                        arrayUniqueByKey?.length > 1 &&
                        arrayUniqueByKey?.map(item =>
                            <button
                                type="button"
                                className={alignment == item.groupId ? `btn btn-primary ${item?.surveyType?.replace(/\s+/g, "")}` : 'btn btn-light buttonToggleBg'}
                                onClick={(e) => handleAlignment(e, item.groupId)}
                            >
                                {item.groupName}
                            </button>
                        )
                    }
                </div>
            </div>
            {
                chartItem?.data?.map((list) => (
                    list.groupId == alignment &&
                    <>
                        <div key={list.id} className="col-md-12 col-sm-12 col-12 mb-2">
                            <div className="row chartMetaHead mb-2">
                                <h4 className={`chart-title-1`}>{list?.name}</h4>
                                <div className='row'>
                                    {
                                        list?.metaData.map((item) => (
                                            <>
                                                <div className="col-md-4 col-sm-12 col-12">
                                                    <p>{list?.surveyType} N={item?.payers} </p>
                                                </div>
                                                {
                                                    item?.lives > 0 &&
                                                    <div className="col-md-8 col-sm-12 col-12">
                                                        <p>Commercial Lives N={millionConvert(item?.lives)} </p>
                                                    </div>
                                                }
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div style={{ height: 400, width: '100%' }}>
                                    <DataGrid
                                        className={`secondTable table_${list?.surveyType?.replace(/\s+/g, "")}`}
                                        rows={list?.rows || []}
                                        getRowId={() => Math.floor(Math.random() * 100000000)}
                                        columns={columnDef(list?.header) || []}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 10 },
                                            },
                                        }}
                                        pageSizeOptions={[5, 10, 20, 30, 50]}
                                        hideFooterSelectedRowCount
                                        getRowHeight={() => 'auto'}
                                        sx={{
                                            '& .MuiDataGrid-cell': {
                                                py: '8px',
                                            },
                                        }}
                                        slots={{ toolbar: CustomToolbar }}
                                        slotProps={{ toolbar: { printOption: { disableToolbarButton: true } } }}

                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ))
            }
            <div className='col-md-12 col-sm-12 mt-3'>
                <ChartFooter data={chartItem} />
            </div>
        </>

    )
}