import React, { useState, useEffect, useCallback } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { StackOptionConfigWithXaxis } from './ChartConfig/StackChartConfigwithXaxis';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ChartFooter } from './ChartFooter';
import { MeanArea, subHArea, payerPopOverlay } from './ChartConfig/ChartConst';
import { OverlayTable } from '../Tables/OverlayTable';
import { chartEvent } from './ChartConfig/ChartConst';
import { useSelector } from 'react-redux';
import { millionConvert } from '../../../Constants/Constant';
import { ToggleSingleOverlayTableView } from "../Tables/CommonTabularComponents/ToggleSingleOverlayTableView";
import { PopOverOverlayTableView } from "../Tables/CommonTabularComponents/PopOverOverlayTableView";
import { CustomToolTip } from './ChartConfig/CustomToolTip';
import { ToggleOverlayTableView } from "../Tables/CommonTabularComponents/ToggleOverlayTableView";
import "../Chart.css";
NoDataToDisplay(Highcharts);

export const MeanOneRowStackGraph = ({ chartItem }) => {
    const [chart, setChart] = useState(null);
    const callback = useCallback((chart) => {
        setChart(chart);
    }, []);

    const [show, setShow] = useState(false);
    const [dataFilter, setDataFilter] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const [chartIndex, setChartIndex] = useState();

    const toggleValue = useSelector((state) => state.selectedItems.selectedQToggle);
    const selectedLOBValue = useSelector((state) => state.selectedItems.selectedLOBValue);
    const handleShow = (props, id, name, i) => {
        const keyVal = chartItem?.chartId > 38 ? name : props
        if (chartEvent[chartItem?.chartId]?.find(item => item == keyVal)) {
            setChartIndex(i);
            setSelectedValue(props);
            setShow(true);
        } else {
            setShow(false)
        }
    };

    useEffect(() => {
        if (selectedValue) {
            const filterData = chartItem?.chartId > 38 ?
                chartItem?.data[chartIndex]?.baseData?.rows?.filter(item => item?.OptionId == selectedValue)
                : chartItem?.data[chartIndex]?.baseData?.data?.filter(item => item?.filterColumn == selectedValue)

            setDataFilter(filterData);
        }
    }, [chartItem, selectedValue])

    return (
        <>
            {
                chartItem?.data.map((list, i, { length }) => (
                    <div className={`col-md-12 col-sm-12 col-12`} key={`${chartItem?.chartId}-${list.id}-${i}`}>
                        {
                            subHArea.find(item => item === chartItem?.chartId) &&
                            <div className="row chartMetaHead my-2">
                                <h4 className={`chart-title-1`}>{list?.name || list?.surveyType}</h4>
                                <div className='row'>
                                    {
                                        list?.metaData.length > 0 &&
                                        list.metaData.map((item, index) =>
                                            <>
                                                <div className="col-md-3 col-sm-12 col-12">
                                                    <p>{list?.surveyType} N={item?.payers} </p>
                                                </div>
                                                {
                                                    item?.lives > 0 &&
                                                    <div className="col-md-8 col-sm-12 col-12">
                                                        <p>{selectedLOBValue?.name} Lives N={millionConvert(item?.lives)} </p>
                                                    </div>
                                                }

                                            </>
                                        )}

                                </div>
                            </div>
                        }
                        <div key={toggleValue} className='row chartPanel'>
                            <div className={MeanArea.find(item => item === chartItem?.chartId) ? 'col-md-11 col-sm-11 col-12' : 'col-md-11 col-sm-11 col-12'} key={list.id}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={StackOptionConfigWithXaxis(list, chartItem, list.id, handleShow, i, length)}
                                // callback={payerPopOverlay?.find(item => item == chartItem?.chartId) && callback}
                                />
                                {
                                    <CustomToolTip chart={chart} countIndex={i}>
                                        {(formatterContext) => {
                                            const { x, y, key, series } = formatterContext;
                                            const filterData = chartItem.chartId > 38 ?
                                                chartItem?.data[i]?.baseData.rows.filter(item => item.SeriesValue == series.options.id)
                                                : chartItem?.data[i]?.baseData.data.filter(item => item.filterColumn == series.options.id)

                                            return (
                                                <div style={{ width: "350px", backgroundColor: "#FFF", zIndex: 1 }}>
                                                    {
                                                        chartItem.chartId > 38 ?
                                                            <PopOverOverlayTableView
                                                                dataFilter={filterData}
                                                                chartItem={chartItem}
                                                            />
                                                            :
                                                            <OverlayTable
                                                                dataFilter={filterData}
                                                                chartItem={chartItem}
                                                            />
                                                    }
                                                </div>
                                            );
                                        }}
                                    </CustomToolTip>
                                }
                            </div>
                            {
                                MeanArea.find(item => item === chartItem?.chartId) &&
                                <div className={`col-md-1 col-sm-1 col-12 meanContainer`} key={list.id}>
                                    <div className="meanArea">
                                        <span><b>Mean</b></span>
                                        <p>{(list?.mean)?.toFixed(1)}</p>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                ))
            }
            {
                show &&
                <div className='row' >
                    {
                        chartItem.chartId > 38 ?
                            // <ToggleSingleOverlayTableView
                            //     dataFilter={dataFilter}
                            //     chartItem={chartItem}
                            // />
                            <ToggleOverlayTableView
                                dataFilter={dataFilter}
                                chartItem={chartItem}
                                toggeleIndex={chartIndex}
                            />
                            :
                            <OverlayTable
                                dataFilter={dataFilter}
                                chartItem={chartItem}
                            />
                    }
                </div>
            }
            <div className='col-md-12 col-sm-12'>
                <ChartFooter data={chartItem} />
            </div>
        </>

    )
}