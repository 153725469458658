import React from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport
} from '@mui/x-data-grid';
import { columnChartHeaderDef } from "../ColumnConfig";
import "../style.css";

export const OverlayPlotTableView = ({ chartItem, dataFilter, toggeleIndex, selectedPlot }) => {

    const filterRowTable = (rowList, filterParam) => {
        const filterData = rowList?.filter(item =>
            filterParam?.every(key => (item[key] || item[key] === 0))
        )
        return filterData;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer >
                <GridToolbarExport
                    printOption={{ hideFooter: true, hideToolbar: true }}
                    csvOptions={{
                        fileName: chartItem?.title
                    }} />
            </GridToolbarContainer>
        );
    }
    return (
        <>
            {
                dataFilter &&
                <>
                    <div className="col-md-12 col-sm-12 col-12 mb-2">
                        <div className="row">
                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    className={`secondTable table_${selectedPlot?.surveyType?.replace(/\s+/g, "")}`}
                                    rows={
                                        filterRowTable(dataFilter, selectedPlot?.headers?.TabularData)
                                    }
                                    getRowId={() => Math.floor(Math.random() * 100000000)}
                                    columns={columnChartHeaderDef(selectedPlot?.headers?.TabularData, 3) || []}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 10 },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10, 20, 30, 50]}
                                    hideFooterSelectedRowCount
                                    getRowHeight={() => 'auto'}
                                    sx={{
                                        '& .MuiDataGrid-cell': {
                                            py: '8px',
                                        },
                                    }}
                                    slots={{ toolbar: CustomToolbar }}
                                    slotProps={{ toolbar: { printOption: { disableToolbarButton: true } } }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        </>

    )
}