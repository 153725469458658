export const OptionConfig = (chartItem) => {    
    const options = {
        chart: {
            type: 'bar',
            height: 150,
            marginBottom:10,
            marginTop:40
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        title: {
            text: '',
            align: 'center'
        },
        lang: {
            noData: 'No Data For Selected Filters'
        },
        noData: {
            style: {
                fontSize: '14px !important',
                fill: '#000 !important',
            }
        },
        xAxis: {
            categories: chartItem?.categories.map(({name})=> name),
             title: {
                text: null
            },
            labels: {
                style: {
                    fontSize: '14px',
                    fontWeight: 800,
                    color: "#000"
                }
            }
        },
        colors: ["#39576C", "#798F44"],
        yAxis: {
            visible: false,
            tickPositioner() {
                const tickPositions = [];
                for (let i = 1; i < 21; i++) {
                    tickPositions.push(100 / 20 * i)
                }
                tickPositions.unshift(0)
                return tickPositions
            },
        },
        tooltip: {
            // valueSuffix: ' %'
            formatter: function () {
                return `${this.y.toFixed(1)}%`
            },
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    // format: '{y}%',
                    formatter: function () {
                        return `${this.y.toFixed(1)}%`
                    },
                },
                events: {
                    legendItemClick: function () {
                        return false; // <== returning false will cancel the default action
                    }
                }
            },
            series: {
                groupPadding: 0.09,
                pointPadding: 0,
            }
        },
        legend: {
            enabled: true,
            align: 'center',
            verticalAlign: 'top',
            y: 10,
            floating: true,
        },
        credits: {
            enabled: false
        },
        series: chartItem?.series
    }
    return options;
} 