import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './LoginButton';
import { Main } from './main';
import { LoadingSpinner } from './Components/LoadingSpinner';
import './App.css';

function AuthLogin() {

  const {
    isLoading,
    error,
    isAuthenticated,
    logout
  } = useAuth0();

  const handleClick = () => {
    logout({ logoutParams: { returnTo: window.location.origin } })
  }
  return (
    <>
      {
        error ?
          <div className="text-center text-black">
            <p className="loginPageErrorMsg">
              <span>
                <i class="fa-solid fa-user-xmark px-2"></i>
              </span>User is not Authorised
              <button
                type="button"
                className='btn btn-primary mx-5'
                onClick={() => handleClick()}
              >
                Back to Login
              </button></p>
          </div>
          :
          isLoading ?
            <LoadingSpinner />
            :
            isAuthenticated ?
              <Main />
              :
              <LoginButton />
      }
    </>
  );
}

export default AuthLogin;
