import React from 'react';
import { ChartCard } from './ChartCard';
import { ChartCardButtons } from './ChartCardButtons';
import { ChartView } from './ChartView';
import '../Chart.css';

export const ChartViewContainer = ({ selectedFilterItems, subTopicChartList }) => {
    return (
        <>
            <div className="col-sm-12 col-md-12 col-10 mb-3" >
                {
                    subTopicChartList?.map((listItem) => (
                        <ChartView key={listItem?.chartId} listItem={listItem} selectedFilterItems={selectedFilterItems} />
                       
                    ))
                }
            </div>
        </>
    )
}