import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { setToken } from './Redux/Actions/selecetdItemsSlice';

export const TokenUtility = async () => {
    const {
        getAccessTokenSilently,
    } = useAuth0();

    const dispatch = useDispatch();

    try {
        const token = await getAccessTokenSilently();
        dispatch(setToken(token));
    }
    catch (err) {
        console.log('token error');
    }
} 