import React, { useEffect, useState } from 'react';
import { TopicItem } from './TopicItem';
import { reportTypeList } from '../../Constants/Constant';
import { useSelector } from 'react-redux';
import { ItemList } from './ItemList';
import { appConst } from '../../Constants/Constant';
import "./topic.css";

export const TopicItemReportType = ({ subModuleFilterData, selectedSubModule }) => {

    const selectePrevYear = useSelector((state) => state.selectedItems.selectedTopic)

    const [selectedReportType, setSelectedReportType] = React.useState();
    const [ReportTypeFilterData, setReportTypeFilterData] = useState([]);

    const handleReportType = (param) => {
        setSelectedReportType(param);
    };

    const uniqueFunc = (subModuleFilterData) => {
        const arrayUnique = [...new Map(subModuleFilterData?.map(item =>
            [item['reportTypeId'], item])).values()].sort((a, b) => a?.reportTypeId - b?.reportTypeId);
        return arrayUnique;
    }
    const filterReportTyeList = subModuleFilterData?.filter(list => list.reportTypeId)
    const arrayUniqueByKey = uniqueFunc(filterReportTyeList);

    useEffect(() => {
        if (!selectedReportType) {
            const defaultReportType = arrayUniqueByKey?.find(item => item.reportTypeId == selectePrevYear?.reportTypeId) ? selectePrevYear?.reportTypeId : arrayUniqueByKey[0]?.reportTypeId;
            setSelectedReportType(defaultReportType);
        }
    }, [arrayUniqueByKey])

    useEffect(() => {
        const filterArrayList = subModuleFilterData?.filter(list => list.reportTypeId == selectedReportType)
        setReportTypeFilterData(filterArrayList);
    }, [selectedReportType, subModuleFilterData])


    return (
        <>
            <div className='row my-2'>
                <div className='col-md-2 col-sm-3 col-11'>
                    <div className="col-md-12 col-sm-12 col-12 d-flex mb-2 justify-content-center">
                        <div className="btn-group-vertical subModuleBtn" role="group" aria-label="Basic example">
                            {
                                reportTypeList?.find(item => item !== selectedSubModule) &&
                                arrayUniqueByKey?.map(item =>
                                    <button
                                        type="button"
                                        className={selectedReportType == item.reportTypeId ? 'btn btn-primary py-2 px-2 subModuleBtn' : 'btn btn-light py-2 px-2  subModuleBtn buttonToggleBg'}
                                        onClick={() => handleReportType(item.reportTypeId)}
                                    >
                                        {item?.reportTypeName}
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className='col-md-9 col-sm-8 topicCardContainer pb-2 ps-0'>
                    {
                        reportTypeList?.find(item => item === selectedSubModule) ?
                            <>
                                {
                                    subModuleFilterData?.some(({ isAuthorized }) => isAuthorized) ?
                                        subModuleFilterData?.map((list => (<TopicItem key={list.topicId} list={list} />)))
                                        :
                                        <div className="py-3 ps-2">{appConst.topicAuthText}</div>
                                }
                                {
                                    subModuleFilterData?.some(({ isAuthorized }) => !isAuthorized) &&
                                    <ItemList
                                        item={subModuleFilterData}
                                    />
                                }
                            </>
                            :
                            ReportTypeFilterData[0]?.reportTypeId == selectedReportType &&
                                subModuleFilterData ?
                                <>
                                    {
                                        ReportTypeFilterData?.some(({ isAuthorized }) => isAuthorized) ?
                                            ReportTypeFilterData?.map((list => (<TopicItem key={list.topicId} list={list} />)))
                                            : <div className="py-3 ps-2">{appConst.topicAuthText}</div>
                                    }
                                    {
                                        ReportTypeFilterData?.some(({ isAuthorized }) => !isAuthorized) &&
                                        <ItemList
                                            item={ReportTypeFilterData}
                                        />
                                    }
                                </>
                                : "No Topics Available"
                    }
                </div>

            </div>
        </>
    )

}