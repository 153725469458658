import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { OptionConfig } from './ChartConfig/OneRowTwoColBar';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ChartFooter } from './ChartFooter';
import { millionConvert } from '../../../Constants/Constant';
import { useSelector } from 'react-redux';
import "../Chart.css";
NoDataToDisplay(Highcharts);

export const OneRowTwoColBarChart = ({ chartItem }) => {
    const [alignment, setAlignment] = React.useState(1);
    const toggleValue = useSelector((state) => state.selectedItems.selectedQToggle);
    const selectedLOBValue = useSelector((state) => state.selectedItems.selectedLOBValue);

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const arrayUniqueByKey = [...new Map(chartItem?.data.map(item =>
        [item['groupId'], item])).values()];

    return (
        <>
            <div className="col-md-12 col-sm-12 col-12 d-flex mb-2 justify-content-center">
                <div className="btn-group" role="group" aria-label="Basic example">
                    {
                        arrayUniqueByKey?.map(item =>
                            <button
                                type="button"
                                className={alignment == item.groupId ? `btn btn-primary ${item?.surveyType?.replace(/\s+/g, "")}` : 'btn btn-light buttonToggleBg'}
                                onClick={(e) => handleAlignment(e, item.groupId)}
                            >
                                {item.groupName}
                            </button>
                        )
                    }
                </div>
            </div>
            {
                chartItem?.data.map((list) => (
                    list.groupId == alignment &&
                    <>
                        <div key={list.id} className="col-md-12 col-sm-12 col-12 mb-2">
                            {/* <div className="row chartMetaHead">
                                <h4 className={`chart-title-1`}>{list?.name}</h4> */}
                            <div className="col-md-12 col-sm-12 col-12 chartMetaHead d-flex mb-2">
                                {
                                    list?.metaData.length > 0 &&
                                    list?.metaData.map(item => (
                                        // <>
                                        //     <div className="col-md-4 col-sm-12 col-12">
                                        //         <p>{item?.categoryName} N={item?.payers} </p>
                                        //     </div>
                                        //     {

                                        //         item?.lives > 0 &&
                                        //         <div className="col-md-8 col-sm-12 col-12">
                                        //             <p>Commercial Lives N={millionConvert(item?.lives)} </p>
                                        //         </div>
                                        //     }

                                        // </>
                                        <div key={item?.categoryId} className='col-md-6 col-sm-12 col-12 chartBorder mx-2'>
                                            <h4 className={`chart-title-1`}>{list?.name || item?.categoryName}</h4>
                                            <div className='row'>
                                                <div className="col-md-6 col-sm-12 col-12">
                                                    <p>{item?.categoryName} N={item?.payers} </p>
                                                </div>
                                                {
                                                    item?.lives > 0 &&
                                                    <div className="col-md-6 col-sm-12 col-12">
                                                        <p>{selectedLOBValue?.name} Lives N={millionConvert(item?.lives)} </p>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {/* </div> */}
                            <div key={toggleValue} className="row">
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={OptionConfig(list)}
                                />
                            </div>
                        </div>
                    </>
                ))
            }
            <div className='col-md-12 col-sm-12'>
                <ChartFooter data={chartItem} />
            </div>
        </>

    )
}