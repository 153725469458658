import React from 'react';
import { customLabel } from '../../../Constants/LocalResource';
import "../Chart.css";

export const CustomInfo = ({ chartItem }) => {
    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: customLabel[chartItem?.chartId]?.text }}></div>
        </>

    )
}