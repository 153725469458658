import React, { useEffect, useState } from 'react';
import Table from './component/Table';
import { getRowData, getColDef, getReportHeader, getTabColor } from './component/helper';
import './customTableStyle.css';

export const SDContainer = ({ data }) => {


    const [tabIndex, setTabIndex] = useState();
    const [dataFormate, setDataFormate] = useState();
    const [rowListData, setRowListData] = useState();
    const [colListData, setColListData] = useState();

    const handleTabIndex = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    useEffect(() => {
        const dataList = data && getRowData(data);
        setDataFormate(dataList)
        setTabIndex(Number(Object.keys(dataList)[0]));
    }, [])

    useEffect(() => {
        const colData = getColDef(dataFormate?.[tabIndex]?.['headerName']);
        const rowData = dataFormate?.[tabIndex]?.['rowData'];
        setColListData(colData);
        setRowListData(rowData)
    }, [tabIndex])

    return (
        <>

            <div className='report'>
                <div className="col-md-12 col-sm-12 col-12 d-flex mb-2 justify-content-center">
                    <div className="btn-group" role="group" aria-label="Basic">
                        {
                            dataFormate &&
                            Object.keys(dataFormate).length > 1 &&
                            Object.keys(dataFormate)?.map(item =>
                                <button
                                    type="button"
                                    className={tabIndex == item ? `btn btn-primary ${getTabColor(Number(item), 'bg')}` : 'btn btn-light buttonToggleBg'}
                                    onClick={(e) => handleTabIndex(e, Number(item))}
                                >
                                    {getReportHeader(Number(item))}
                                </button>
                            )
                        }
                    </div>
                </div>
                {
                    dataFormate && Object.keys(dataFormate).length < 2 &&
                    <h4>{getReportHeader(tabIndex)}</h4>
                }
                {
                    dataFormate &&
                    <Table rowData={rowListData} colData={colListData} reportId={tabIndex} />
                }
            </div>
        </>
    );
}
