import React, { useState, useEffect } from 'react';
import { removeSpace } from '../../../Constants/Constant';

export const FilterBox = ({
    filterItemList,
    selectedFilters,
    setSelectedFilters
}) => {
    const [filterState, setFilterState] = useState({});

    useEffect(() => {
        if (filterItemList) {
            const filterParam = {
                ...filterState,
                [filterItemList.name]: filterItemList.values
            }
            setFilterState(filterParam);
        }
    }, [filterItemList])

    let formateFilterList = [];
    if (Object.keys(filterState).length > 0) {
        formateFilterList = filterState[Object.keys(filterState)];
    }

    const handleChange = (evt, param) => {
        const listSearch = param?.values?.filter(x => (x.name.toUpperCase()).includes(evt.target.value.toUpperCase()));
        const filterData = {
            ...filterState,
            [param.name]: listSearch
        }
        setFilterState(filterData)
    }

    const handleCheckedState = (evt, param) => {
        let selcetedItem = {
            ...selectedFilters,
        }
        if (evt.target.checked) {
            // selcetedItem[evt.target.name]?.push(param.id)
            if (selcetedItem[evt.target.name]) {
                selcetedItem[evt.target.name] = [...selectedFilters[evt.target.name], param.id]
            } else {
                selcetedItem[evt.target.name] = [param.id]
            }
            setSelectedFilters(selcetedItem)
        } else {
            selcetedItem[evt.target.name] = selcetedItem[evt.target.name]?.filter(list => list != param.id);
            setSelectedFilters(selcetedItem)
        }
    }

    const handleSingleCheckedState = (evt, param) => {
        if (evt.target.name === "LOB") {
            if (evt.target.checked) {
                const selcetedItem = {
                    ...selectedFilters,
                    [evt.target.name]: param.id
                }
                setSelectedFilters(selcetedItem)
            }
        } else {
            if (evt.target.checked) {
                const selcetedItem = {
                    ...selectedFilters,
                    [evt.target.name]: param.id
                }
                setSelectedFilters(selcetedItem)
            } else {
                const selcetedItem = {
                    ...selectedFilters,
                    [evt.target.name]: ''
                }
                setSelectedFilters(selcetedItem)
            }
        }
    }
    return (
        <>

            <div className="mb-3 me-3">
                <div className="card p-2 shadow-sm bg-white rounded">
                    <form>
                        <div className="form-group mb-3">
                            <input type="text" className="form-control" onChange={(evt) => handleChange(evt, filterItemList)} aria-describedby="Search" placeholder="Search" />
                        </div>
                    </form>
                    <div className="mh-20 overflow-auto filterCardSize">
                        {
                            formateFilterList.length > 0 ?
                                formateFilterList?.map((item) => (
                                    <div key={`${filterItemList.name}-${item.id}`}>
                                        {
                                            filterItemList?.selectionType == 1 ?
                                                (
                                                    <div className="form-check lh-1 my-0">
                                                        <input
                                                            className="form-check-input my-0"
                                                            type="checkbox"
                                                            name={filterItemList.name}
                                                            // value=""
                                                            checked={Object.keys(selectedFilters).length > 0 ? selectedFilters[filterItemList.name] == item.id : false}
                                                            onChange={(evt) => { handleSingleCheckedState(evt, item) }}
                                                            id={`${filterItemList.name}-${item.id}`}
                                                        />
                                                        <label className="form-check-label my-0" htmlFor={`${filterItemList.name}-${item.id}`}>
                                                            <p>{item?.name}</p>
                                                        </label>
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div className="form-check lh-1 my-0">
                                                        <input
                                                            className="form-check-input my-0"
                                                            type="checkbox"
                                                            onChange={(evt) => { handleCheckedState(evt, item) }}
                                                            // value=""
                                                            checked={Object.keys(selectedFilters).length > 0 ? selectedFilters[removeSpace(filterItemList.name)]?.some(list => { return list == item.id }) : false}
                                                            name={removeSpace(filterItemList.name)}
                                                            id={`${filterItemList.name}-${item.id}`}
                                                        />
                                                        <label className="form-check-label my-0" htmlFor={`${filterItemList.name}-${item.id}`}>
                                                            <p>{item?.name}</p>
                                                        </label>
                                                    </div>
                                                )
                                        }

                                    </div>
                                ))
                                :
                                <p>No records found</p>
                        }
                    </div>
                </div>
            </div>

        </>

    )
}