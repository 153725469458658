import React from 'react';
import { ModuleCard } from './ModuleCards/ModuleCard';
import { subsciptionModule } from '../../Constants/Constant';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { useSelector } from 'react-redux';
import { useGetModulesQuery } from '../../Redux/Actions/moduleSlice';
import { useAuth0 } from '@auth0/auth0-react';
import "./Home.css";

export const Home = () => {
    const token_id = useSelector((state) => state.selectedItems.token_id)
    const { data, error, isLoading } = useGetModulesQuery(token_id)

    const {
        user,
      } = useAuth0();

    let moduleList = [];
    if (data) {
        moduleList = [...data, subsciptionModule]
    }
    const displayModuleList = () => {
        return moduleList?.map(list => (<ModuleCard key={list.moduleId} list={list} />))
    }
    return (
        <div className='container ms-2 indexPosition'>
            <div className="row mt-3">
                <div className="col-sm-6 col-md-6 col-10">
                    <h2>MMIT Advisory Services Portal</h2>
                </div>
                <div className="col-sm-6 col-md-6 col-12" align="right">
                    <div className=" d-flex">
                        <div className="collapse fade" id="searchForm12">
                            <input id="search" type="search" className="form-control border-0" placeholder="search" />
                        </div>
                        <a className="ml-auto"
                            href="#searchForm12"
                            data-target="#searchForm12"
                            data-toggle="collapse">
                            <i className="mdi mdi-magnify"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <p>Welcome, <b>{user?.name}</b>, to the MMIT Market Advisory/Research launch portal.</p>
            </div>
            <div className="row mt-3 homeModules">
                {
                    error ?
                        <p className="text-warning">Something went wrong try again</p>
                        :
                        isLoading ?
                            <LoadingSpinner /> :
                            moduleList ?
                                displayModuleList() :
                                null
                }

            </div>
            {/* commented Knowledge Bot for now  */}
            
            {/* <div className="knowlegePlaceholder">
                <div className="ktBoat shadow">
                    <h5>Welcome! I'll be your onboarding assistant. Let's get you up an running.</h5><br />
                    <h5><a style={{ textDecoration: 'underline' }}>Knowledge Bot</a></h5>
                </div>
                <a className="d-block pt-3 pb-3 text-center link-dark text-decoration-none" title="Knowledge Bot" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Icon-only">
                    <img src="https://contracts-dev-use-mmit-wa-01.azurewebsites.net/assets/MMIT-KO-logo-no-text.svg" alt="ProductName" width="30" height="24" />
                    <span className="visually-hidden">Icon-only</span>
                </a>
            </div> */}
        </div>
    )
}