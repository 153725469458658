import AppRouter from './AppRouter';
import { TokenUtility } from './TokenUtility';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './LoginButton';
import { LoadingSpinner } from './Components/LoadingSpinner';
import './App.css';

function App() {
  const {
    isLoading,
    error,
    isAuthenticated,
    logout
  } = useAuth0();

  TokenUtility();
  return (
    <>
      {
        error ?
          <h3>{error}</h3>
          :
          isLoading ?
            <LoadingSpinner />
            :
            isAuthenticated ?
              <AppRouter />
              :
              <LoginButton />
      }
    </>
  );
}

export default App;
