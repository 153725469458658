let getAccessTokenSilently = null;

export const sec = {
    getAccessTokenSilently: () => getAccessTokenSilently,
    setAccessTokenSilently: (func) => (getAccessTokenSilently = func)
};


let getIdTokenClaims = null;
export const secClaim = {
    getIdTokenClaims: () => getIdTokenClaims,
    setIdTokenClaims: (func) => (getIdTokenClaims = func)
};