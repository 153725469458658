// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const sampleDescriptorApi = createApi({
    reducerPath: 'sampleDescriptorApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_MA_API}`,
        prepareHeaders: async (headers, { getState }) => {
            const access_token = getState().selectedItems.token_id;
            if (access_token) {
                headers.set('Access-Control-Allow-Origin', '*');
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getTopic: builder.query({
            query: ({ topicId, token, year }) => ({
                url: `topics/${topicId}/sample-descriptor?year=${year}`,
                headers: { 'Authorization': `Bearer ${token}` }
            }),
            keepUnusedDataFor: 3600,
        }),
    }),
})

export const { useGetTopicQuery } = sampleDescriptorApi;