
export const customLabel = {
    63:{
        "text":`<p style="font-size:16px">There is an emerging trend among third-party organizations, such as Payer Matrix, Payd Health, and Sharx, who offer services to health plans and PBMs to reduce their spend on specialty drug costs through the following process </br>
        <ul>
        <li> A 3rd party organization who offers securing alternative funding for specialty drugs researches the copay assistance and foundation support of specialty drugs </li>
        <li> The 3rd party advises the health plan to remove coverage for some specialty drugs </li>
        <li> When a physician prescribes a drug on this list, it comes back as not covered </li>
        <li> The 3rd party submits a patient assistance request to the PAP or charity foundation on behalf of the patient </li>
        <li> The manufacturer or charity foundation pays for the drug through this program, depleting the funds that would otherwise be used for charity patients </li>
        </ul> 
        
        Originally, these third-party organizations would mainly target small employers, unions, and education health plans where patients may really need assistance. However, recently they have started to target larger employer groups, and are starting to work with PBMs as well as selling their services as a way to reduce drug spend.
        </p> 
        `,
        subTitle:"(Note: Information as Displayed to Respondents)"
    }
}