import React, { useState, useCallback } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { OptionConfig } from './ChartConfig/PlainSingleBar';
import { OptionConfigNoPercent } from './ChartConfig/PlainSingleBarNoPercentage';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ChartFooter } from './ChartFooter';
import { useSelector } from 'react-redux';
import { subHArea, payerPopOverlay, noPercentBar } from './ChartConfig/ChartConst';
import { millionConvert } from '../../../Constants/Constant';
import { ToggleSingleOverlayTableView } from "../Tables/CommonTabularComponents/ToggleSingleOverlayTableView";
import { CustomToolTip } from './ChartConfig/CustomToolTip';
import { PopOverOverlayTableView } from "../Tables/CommonTabularComponents/PopOverOverlayTableView";
import { OverlayTable } from '../Tables/OverlayTable';
import "../Chart.css";
NoDataToDisplay(Highcharts);

export const PlainSingleBarChart = ({ chartItem }) => {
    const [chart, setChart] = useState(null);
    const callback = useCallback((chart) => {
        setChart(chart);
    }, []);

    const toggleValue = useSelector((state) => state.selectedItems.selectedQToggle);
    const selectedLOBValue = useSelector((state) => state.selectedItems.selectedLOBValue);
    return (
        <>
            <div key={toggleValue}>
                {
                    chartItem?.data.map((list, i) => (
                        <>
                            {
                                subHArea.find(item => item === chartItem?.chartId) &&
                                <div className="row chartMetaHead my-2">
                                    <h4 className={`chart-title-1`}>{list?.name || list?.surveyType}</h4>
                                    <div className='row'>
                                        {
                                            list?.metaData.length > 0 &&
                                            list.metaData.map((item) =>
                                                <>
                                                    <div className="col-md-3 col-sm-12 col-12">
                                                        <p>{list?.surveyType} N={item?.payers} </p>
                                                    </div>
                                                    {
                                                        item?.lives > 0 &&
                                                        <div className="col-md-8 col-sm-12 col-12">
                                                            <p>{selectedLOBValue?.name} Lives N={millionConvert(item?.lives)} </p>
                                                        </div>
                                                    }

                                                </>
                                            )}

                                    </div>
                                </div>
                            }
                            <div className="col-md-12 col-sm-12 col-12" key={list.id}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={
                                        noPercentBar?.find(itemList=> itemList == chartItem?.chartId)?
                                        OptionConfigNoPercent(list, chartItem, list.id):
                                        OptionConfig(list, chartItem, list.id)
                                    }
                                    // callback={payerPopOverlay?.find(item => item == chartItem?.chartId) && callback}
                                />
                                {
                                    <CustomToolTip chart={chart} countIndex={i}>
                                        {(formatterContext) => {
                                            const { x, y, key } = formatterContext;
                                            const chartCategaory = chartItem?.data[0]?.categories.filter(item => item.name == key)[0];
                                            const filterData = chartItem.chartId > 38 ?
                                                chartItem?.data[0]?.baseData.rows.filter(item => item.OptionId == chartCategaory.id)
                                                : chartItem?.data[0]?.baseData.data.filter(item => item.filterColumn == chartCategaory.id)

                                            return (
                                                <div style={{ width: "350px", backgroundColor: "#FFF", zIndex: 1 }}>
                                                    {
                                                        chartItem.chartId > 38 ?
                                                            <PopOverOverlayTableView
                                                                dataFilter={filterData}
                                                                chartItem={chartItem}
                                                            />
                                                            :
                                                            <OverlayTable
                                                                dataFilter={filterData}
                                                                chartItem={chartItem}
                                                            />
                                                    }
                                                </div>
                                            );
                                        }}
                                    </CustomToolTip>
                                }
                            </div>
                        </>
                    ))
                }
            </div>
            <div className='col-md-12 col-sm-12'>
                <ChartFooter data={chartItem} />
            </div>
        </>

    )
}