import { ChartColors, ChartAlgin, chartOverlay, chartEvent, defaultChartColor, defaultBarAlign } from './ChartConst';
import { displayPophoverTable } from "./ChartUtility";
import { defineColorCode, SERVEY_TYPE_PAYER } from './ChartColorConfig';

export const OptionConfig = (chartItem, chartData, id, handleShow) => {
    const options = {
        chart: {
            type: 'bar',
            height: ChartAlgin?.[chartData?.chartId]?.height || defaultBarAlign?.height,
            marginBottom: ChartAlgin?.[chartData?.chartId]?.bottom || defaultBarAlign?.bottom,
            marginTop: ChartAlgin?.[chartData?.chartId]?.top || defaultBarAlign?.top,
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        title: {
            text: '',
            align: 'center'
        },
        lang: {
            noData: 'No Data For Selected Filters'
        },
        noData: {
            style: {
                fontSize: '14px !important',
                fill: '#000 !important',
            }
        },
        xAxis: {
            categories: chartItem?.categories.map(({ name }) => name),
            title: {
                text: null
            },
            labels: {
                style: {
                    fontSize: '14px',
                    fontWeight: 800,
                    color: "#000"
                },
                //TODO: Adding Custom Icon 
                useHTML: false,
                formatter: function () {
                    return `${this.value} <span style="color:#000;"><i class="fa-solid fa-circle-info"></i></span> `
                    if (chartOverlay?.find(item => item === chartData.chartId)) {
                        if (chartEvent[chartData.chartId].find(item => item?.toLowerCase() == this.value?.toLowerCase())) {
                            return `${this.value} <span style="color:#000;"><i class="fa-solid fa-circle-info"></i></span> `
                        } else {
                            return this.value
                        }
                    } else {
                        return this.value;
                    }
                },
            },
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0
        },
        // colors: ChartColors?.[chartData?.chartId]?.[id] || defaultChartColor,
        colors: defineColorCode(chartItem?.surveyType?.replace(/\s+/g, ""), chartItem, chartData),
        yAxis: {
            visible: false,
            tickPositioner() {
                const tickPositions = [];
                for (let i = 1; i < 11; i++) {
                    tickPositions.push(100 / 10 * i)
                }
                tickPositions.unshift(0)
                return tickPositions
            },
        },
        tooltip: {
            // valueSuffix: ' %',
            outside: true,
            useHTML: true,
            formatter: function () {
                if (chartItem?.surveyType === SERVEY_TYPE_PAYER) {
                    const chartCategaory = chartItem?.categories?.filter(item => item.name == this.key)[0];
                    const filterData = chartData?.chartId > 38 ?
                        chartItem?.baseData?.rows?.filter(item => (item.OptionId == chartCategaory.id) || item.SeriesValue == chartCategaory.id)
                        : chartItem?.baseData?.data?.filter(item => item.filterColumn == chartCategaory.id)

                    const headerKey = chartData?.chartId > 38 ? chartItem?.baseData?.headers?.MouseoverOverlay?.[0] || "Organization" : Object.keys(chartItem?.baseData?.mouseoverOverlay)[0];
                    const uniqueList = filterData.filter((obj, index) => {
                        return index === filterData.findIndex(o => obj?.[headerKey] === o?.[headerKey]);
                    }).slice(0, 10);
                    const displayResult = displayPophoverTable(uniqueList, headerKey)
                    return displayResult;
                } else {
                    return `${this.x}<br/> <span style="color:${this.series.color}">\u25CF</span> ${this.series.name}: <b> ${this.y.toFixed(1)}% </b>`;
                }
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    // format: '{y}%',
                    // TODO: adding custom icons
                    useHTML: true,
                    formatter: function () {
                        if (chartEvent[chartData?.chartId]?.find(item => item?.toLowerCase() == this.key?.toLowerCase())) {
                            return `<span>${this.y.toFixed(1)}%  <span style="color:#000;font-size:16px !important" title="ram"><i class="fa-solid fa-eye fa-lg"></i></span></span>`
                        } else {
                            return `${this.y.toFixed(1)}%`
                        }
                    },
                },
                events: {
                    legendItemClick: function () {
                        return false;
                    },
                }
            },
            series: {
                cursor: 'pointer',
                groupPadding: 0.09,
                pointPadding: 0,
                point: {
                    events: {
                        click: function () {
                            handleShow(this.category, this.series.options.id)
                        }
                    }
                }
            }
        },
        legend: {
            enabled: false,
            align: 'right',
            verticalAlign: 'top',
            y: -16,
            floating: true,
        },
        credits: {
            enabled: false
        },
        series: chartItem?.series
    }
    return options;
} 