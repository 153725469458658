import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedModules: {},
    selectedTopic: {},
    selectedFilters: {},
    selectedQToggle: false,
    routingNav: [],
    selectedGlobalFilter: {},
    selectedLOBValue: {},
    token_id: null,
}

const selectedItemSlice = createSlice({
    name: 'selectedItems',
    initialState,
    reducers: {
        selectModule: (state, action) => {
            state.selectedModules = action.payload;
        },
        selectTopic: (state, action) => {
            state.selectedTopic = action.payload;
        },
        selectedFilter: (state, action) => {
            state.selectedFilters = action.payload
        },
        selectQToggle: (state, action) => {
            state.selectedQToggle = action.payload
        },
        updateRoutingNav: (state, action) => {
            state.routingNav = action.payload
        },
        updateGlobalFilter: (state, action) => {
            state.selectedGlobalFilter = action.payload
        },
        updateLOB: (state, action) => {
            state.selectedLOBValue = action.payload
        },
        setToken: (state, action) => {
            state.token_id = action.payload
        },
        removeSelectTopic: (state, action) => {
            state.selectedTopic = {};
        },
    }
})

export const { 
    selectModule, 
    selectTopic, 
    selectedFilter, 
    selectQToggle, 
    updateRoutingNav, 
    updateGlobalFilter, 
    updateLOB, 
    setToken,
    removeSelectTopic
 } = selectedItemSlice.actions

export default selectedItemSlice.reducer