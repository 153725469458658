import { createSlice } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';

const initialState = {}

const clearStore = createSlice({
    name: 'clearStoreData',
    initialState,
    reducers: {
        clearResults: (state, action) => {
            if (action.type === 'clearStoreData/clearResults') {
                // this applies to all keys defined in persistConfig(s)
                storage.removeItem('persist:root')
            
                state = {} 
              }
            //   return appReducer(state, action)
        },
    }
})

export const { clearResults } = clearStore.actions

export default clearStore.reducer