import React from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport
} from '@mui/x-data-grid';
import { columnDef } from "../ColumnConfig";
import "../style.css";


export const MultiDataTable = ({ chartItem }) => {
    function CustomToolbar() {
        return (
            <GridToolbarContainer >
                <GridToolbarExport
                    printOption={{ hideFooter: true, hideToolbar: true }}
                    csvOptions={{
                        fileName: chartItem?.title
                    }} />
            </GridToolbarContainer>
        );
    }
    const rowDataFilter = (datalist, filterfield) => {
        const filterData = datalist.filter(list => {
            if (list[filterfield] !== "" && list[filterfield] !== null) {
                return list
            }
        })
        return filterData;
    }

    return (
        <>
            {
                chartItem?.data[0]?.baseData?.tableHeaders &&
                <div className="mb-3" style={{ height: 400, width: '50%' }}>
                    <DataGrid
                        className={`secondTable table_${chartItem?.data[0]?.surveyType?.replace(/\s+/g, "")}`}
                        rows={rowDataFilter(chartItem?.data[0]?.baseData.data, 'description') || []}
                        getRowId={() => Math.floor(Math.random() * 100000000)}
                        columns={columnDef(chartItem?.data[0]?.baseData?.tableHeaders) || []}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                            rowGrouping: {
                                model: ['copayProgramType'],
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 30, 50]}
                        hideFooterSelectedRowCount
                        getRowHeight={() => 'auto'}
                        sx={{
                            '& .MuiDataGrid-cell': {
                                py: '8px',
                            },
                        }}
                        slots={{ toolbar: CustomToolbar }}
                        slotProps={{ toolbar: { printOption: { disableToolbarButton: true } } }}

                    />
                </div>
            }
            {
                chartItem?.data[0]?.baseData?.tableHeaders1 &&
                <div className="mb-3" style={{ height: 400, width: '50%' }}>
                    <DataGrid
                        className={`secondTable table_${chartItem?.data[0]?.surveyType?.replace(/\s+/g, "")}`}
                        rows={rowDataFilter(chartItem?.data[0]?.baseData.data, 'totalScriptVolume') || []}
                        getRowId={() => Math.floor(Math.random() * 100000000)}
                        columns={columnDef(chartItem?.data[0]?.baseData?.tableHeaders1) || []}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                            rowGrouping: {
                                model: ['copayProgramType'],
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 30, 50]}
                        hideFooterSelectedRowCount
                        getRowHeight={() => 'auto'}
                        sx={{
                            '& .MuiDataGrid-cell': {
                                py: '8px',
                            },
                        }}
                        slots={{ toolbar: CustomToolbar }}
                        slotProps={{ toolbar: { printOption: { disableToolbarButton: true } } }}
                    />
                </div>
            }
            {
                chartItem?.data[0]?.baseData?.tableHeaders2 &&
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        className={`secondTable table_${chartItem?.data[0]?.surveyType?.replace(/\s+/g, "")}`}
                        rows={rowDataFilter(chartItem?.data[0]?.baseData.data, 'reasonForIncrease') || []}
                        getRowId={() => Math.floor(Math.random() * 100000000)}
                        columns={columnDef(chartItem?.data[0]?.baseData?.tableHeaders2) || []}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                            rowGrouping: {
                                model: ['copayProgramType'],
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 30, 50]}
                        hideFooterSelectedRowCount
                        getRowHeight={() => 'auto'}
                        sx={{
                            '& .MuiDataGrid-cell': {
                                py: '8px',
                            },
                        }}
                        slots={{ toolbar: CustomToolbar }}
                        slotProps={{ toolbar: { printOption: { disableToolbarButton: true } } }}
                    />
                </div>
            }

        </>
    );
}