import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { moduleApi, topicApi } from './Actions/moduleSlice';
import { subTopicApi } from './Actions/subTopicChartSlice';
import { chartDataApi } from './Actions/chartDataSlice';
import { subTopicTabApi } from './Actions/subTopicTabSlice';
import { sampleDescriptorApi } from './Actions/sampleDescriptorSlice';
import selectedItemReducer from './Actions/selecetdItemsSlice';
import clearStore from './Actions/clearStore';

import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist';


const appReducers = combineReducers({
    clearStore,
    selectedItems: selectedItemReducer,
    [moduleApi.reducerPath]: moduleApi.reducer,
    [topicApi.reducerPath]: topicApi.reducer,
    [subTopicApi.reducerPath]: subTopicApi.reducer,
    [chartDataApi.reducerPath]: chartDataApi.reducer,
    [subTopicTabApi.reducerPath]: subTopicTabApi.reducer,
    [sampleDescriptorApi.reducerPath]: sampleDescriptorApi.reducer,
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [subTopicApi.reducerPath, chartDataApi.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, appReducers);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware(
            {
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
                }
            }
        )
            .concat(
                moduleApi.middleware,
                topicApi.middleware,
                subTopicApi.middleware,
                chartDataApi.middleware,
                subTopicTabApi.middleware,
                sampleDescriptorApi.middleware,
            );
    }
});
