import axios from "axios";

export const getCopayChart = (prop) => {
    return axios.get(`${process.env.REACT_APP_MA_API}charts/${prop}/data?year=2022`, {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    }
    ).then(response => {
        return response.data
    })
}

export const getSubTopic = ({ topicId, subTopicId }) => {
    return axios.get(`${process.env.REACT_APP_MA_API}topics/${topicId}/${subTopicId}/charts?year=2022`, {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    }
    ).then(response => {
        return response.data
    })
}

export const getFilterData = async ({ subTopicId, year, token_id }) => {
    return axios.get(`${process.env.REACT_APP_MA_API}subtopics/${subTopicId}/filters?year=${year}`, {
        'headers': {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token_id}`,
            'Content-Type': 'application/json'
        }
    }
    ).then(response => {
        return response.data
    })
}

export const getChartFilterData = ({ chartId, selectedFilterItems }) => {
    const stakholder = selectedFilterItems?.Stakeholder;
    const typeOfpayers = selectedFilterItems?.TypeofPayers?.join();
    const plans = selectedFilterItems?.Plans?.join();
    return axios.get(`${process.env.REACT_APP_MA_API}charts/${chartId}/data?year=2022&stakeHolder=${stakholder}&typeOfPayers=${typeOfpayers}&plans=${plans}`, {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    }
    ).then(response => {
        return response.data
    })
}