import { Sidebar } from "./View/Sidebar/Sidebar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from './View/Home/Home';
import { WorkProgress } from './View/Home/WorkProgress';
import { Topic } from './View/Topics/Topic';
import { TopicTab } from './View/SubTopic/TopicTab';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import './App.css';

const AppRouter = () => {
  const {
    isAuthenticated,
  } = useAuth0();

  const token_id = useSelector((state) => state.selectedItems.token_id)
  return (
    <>
      {
        isAuthenticated && token_id &&
        <Router>
          <div className='wrapper-container d-flex'>
            <Sidebar />
            <div className='sitecontainer col-md-12 col-sm-12 col-12'>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/notification" element={<WorkProgress />} />
                <Route path="/global-filter" element={<WorkProgress />} />
                <Route path="/topics" element={<Topic />} />
                <Route path="/topics/reports" element={<TopicTab />} />
              </Routes>
            </div>
          </div>
        </Router>
      }

    </>
  );
}

export default AppRouter;
