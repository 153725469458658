import React, { useEffect, useState, useCallback } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { OptionConfig } from './ChartConfig/BarWithStack';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ChartFooter } from './ChartFooter';
import { millionConvert } from '../../../Constants/Constant';
import { useSelector } from 'react-redux';
import { PopOverOverlayTableView } from "../Tables/CommonTabularComponents/PopOverOverlayTableView";
import { CustomToolTip } from './ChartConfig/CustomToolTip';
import { payerPopOverlay, subPayer } from './ChartConfig/ChartConst';
import "../Chart.css";
NoDataToDisplay(Highcharts);

export const BarWithToggeleStackChart = ({ chartItem }) => {
    const [chart, setChart] = useState(null);
    const callback = useCallback((chart) => {
        setChart(chart);
    }, []);

    const [alignment, setAlignment] = React.useState();
    const toggleValue = useSelector((state) => state.selectedItems.selectedQToggle);
    const selectedLOBValue = useSelector((state) => state.selectedItems.selectedLOBValue);

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const arrayUniqueByKey = [...new Map(chartItem?.data.map(item =>
        [item['groupId'], item])).values()];

    useEffect(() => {
        setAlignment(arrayUniqueByKey[0]?.groupId)
    }, [])

    return (
        <>
            <div className="col-md-12 col-sm-12 col-12 d-flex mb-2 justify-content-center">
                <div className="btn-group" role="group" aria-label="Basic example">
                    {
                        arrayUniqueByKey?.map(item =>
                            <button
                                type="button"
                                className={alignment == item.groupId ? `btn btn-primary ${item?.surveyType?.replace(/\s+/g, "")}` : 'btn btn-light buttonToggleBg'}
                                onClick={(e) => handleAlignment(e, item.groupId)}
                            >
                                {item.groupName}
                            </button>
                        )
                    }
                </div>
            </div>
            {
                chartItem?.data.map((list, i) => (
                    list.groupId == alignment &&
                    <>
                        <div key={list.id} className="col-md-12 col-sm-6 col-12 mb-2">
                            <div className="row chartMetaHead">
                                <h4 className={`chart-title-1`}>{list?.name || list?.surveyType}</h4>
                                {
                                    !subPayer?.some((subItem) => subItem == chartItem.chartId) &&
                                    <div className='row'>
                                        {
                                            list?.metaData.length > 0 &&
                                            list.metaData.map((item) =>
                                                <>
                                                    <div className="col-md-3 col-sm-12 col-12">
                                                        <p>{list?.surveyType} N={item?.payers} </p>
                                                    </div>
                                                    {
                                                        item?.lives > 0 &&
                                                        <div className="col-md-8 col-sm-12 col-12">
                                                            <p>{selectedLOBValue?.name} Lives N={millionConvert(item?.lives)} </p>
                                                        </div>
                                                    }
                                                </>
                                            )}

                                    </div>
                                }

                            </div>
                            <div key={toggleValue} className="row">
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={OptionConfig(list, chartItem, list.id, i)}
                                // callback={payerPopOverlay?.find(item => item == chartItem?.chartId) && callback}
                                />
                                {
                                    <CustomToolTip chart={chart} countIndex={i}>
                                        {(formatterContext) => {
                                            const { x, y, key, series } = formatterContext;

                                            const filterData = chartItem.chartId > 38 ?
                                                chartItem?.data[i]?.baseData.rows.filter(item => item.SeriesValue == series.options.id)
                                                : chartItem?.data[i]?.baseData.data.filter(item => item.filterColumn == series.options.id)

                                            return (
                                                <div style={{ width: "350px", backgroundColor: "#FFF", zIndex: 1 }}>
                                                    {
                                                        chartItem.chartId > 38 ?
                                                            <PopOverOverlayTableView
                                                                dataFilter={filterData}
                                                                chartItem={chartItem}
                                                            />
                                                            :
                                                            null
                                                    }
                                                </div>
                                            );
                                        }}
                                    </CustomToolTip>
                                }
                            </div>
                        </div>
                    </>
                ))
            }
            <div className='col-md-12 col-sm-12'>
                <ChartFooter data={chartItem} />
            </div>
        </>
    )
}