import React from "react";
import {
    NavLink
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { clearResults } from '../../Redux/Actions/clearStore';
import { useDispatch } from 'react-redux';
import './Sidebar.css';

export const Sidebar = () => {
    const { logout } = useAuth0();
    const dispatch = useDispatch();

    const sessionClear = () => {
        dispatch(clearResults());
        logout({ logoutParams: { returnTo: window.location.origin } })
    }

    return (
        <div className="sidebar d-flex flex-column flex-shrink-0 bg-primary position-fixed sidebarContainer" >
            <a href="/" className="d-block pt-3 pb-3 text-center link-dark text-decoration-none" title="Market Advisory" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Icon-only">
                <img src="https://contracts-dev-use-mmit-wa-01.azurewebsites.net/assets/MMIT-KO-logo-no-text.svg" alt="ProductName" width="30" height="24" />
                <span className="visually-hidden">Icon-only</span>
            </a>
            <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
                <li className="nav-item">
                    <NavLink to="/" activeclassname="active" className="sideIcons nav-link py-3 border-bottom" aria-current="page" title="Home" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Home">
                        <i className="fa fa-home"></i>
                        <small>Home</small>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/notification" activeclassname="active" className="sideIcons nav-link py-3 border-bottom" title="Dashboard" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
                        <i className="fa fa-bell"></i>
                        <small>Notification</small>
                        <small>Center</small>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/global-filter" activeclassname="active" className="sideIcons nav-link py-3 border-bottom" title="Dashboard" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
                        <i className="fa fa-magnifying-glass"></i>
                        <small>Advanced</small>
                        <small>Search</small>
                    </NavLink>
                </li>
            </ul>
            <div className="dropdown border-top text-center ">
                <a href="#" className="d-flex align-items-center justify-content-center p-3 link-light text-decoration-none dropdown-toggle" id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="assets/images/ContractListIcon.svg" alt="mdo" width="24" height="24" className="rounded-circle" />
                </a>
                <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser3">
                    <li><a className="dropdown-item" href="#">Settings</a></li>
                    <li><a className="dropdown-item" href="#">Profile</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item "  role="button" onClick={() => sessionClear()} >Sign out</a></li>
                </ul>
            </div>
        </div>
    )
}