import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const Table = ({ rowData, colData, reportId }) => {
    return (
        <table>
            <thead>
                <TableHeader columns={colData} reportId={reportId} />
            </thead>
            <TableBody data={rowData} columns={colData} reportId={reportId} />
        </table>
    );
}

export default Table;