import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { StackOptionConfig } from './ChartConfig/StackChartConfig';
import { millionConvert } from '../../../Constants/Constant';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ChartFooter } from './ChartFooter';
import { useSelector } from 'react-redux';
import "../Chart.css";
NoDataToDisplay(Highcharts);

export const TwoColStackGraph = ({ chartItem }) => {
    const toggleValue = useSelector((state) => state.selectedItems.selectedQToggle);
    const selectedLOBValue = useSelector((state) => state.selectedItems.selectedLOBValue);
    return (
        <>
            {

                chartItem?.data.map((list) => (
                    <div className={`chartBorder col-md-6 col-sm-12 col-12 border-right`} key={list.id}>
                        {/* <h4 className={`chart-title-1 `}>{list?.name || list?.surveyType}</h4> */}
                        {
                            list?.metaData.length > 0 &&
                            <div className='row chartMetaHead'>
                                <h4 className={`chart-title-1 `}>{list?.name || list?.surveyType}</h4>
                                <div className="col-md-4 col-sm-12 col-12">
                                    <p>{list?.surveyType} N={list?.metaData[0]?.payers} </p>
                                </div>
                                <div className="col-md-8 col-sm-12 col-12">
                                    <p>{selectedLOBValue?.name} Lives N={millionConvert(list?.metaData[0]?.lives)} </p>
                                </div>
                            </div>
                        }
                        <div key={toggleValue} className='row'>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={StackOptionConfig(list, chartItem, list.id)}
                            />
                        </div>
                    </div>
                ))
            }
            <div className='col-md-12 col-sm-12'>
                <ChartFooter data={chartItem} />
            </div>
        </>

    )
}