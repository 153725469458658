import React, { useEffect } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport
} from '@mui/x-data-grid';
import { columnChartHeaderDef } from "../ColumnConfig";
import { ChartFooter } from '../../Charts/ChartFooter';
import { useSelector } from 'react-redux';
import { millionConvert } from '../../../../Constants/Constant';
import "../style.css";

export const ToggleChartTableView = ({ chartItem }) => {
    const [alignment, setAlignment] = React.useState();

    const selectedLOBValue = useSelector((state) => state.selectedItems.selectedLOBValue);


    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const arrayUniqueByKey = [...new Map(chartItem?.data?.map(item =>
        [item['groupId'], item])).values()];

    useEffect(() => {
        setAlignment(arrayUniqueByKey[0]?.groupId)
    }, [])

    const filterRowTable = (rowList, filterParam) => {
        const filterData = rowList?.filter(item => {
            if (filterParam?.length === 1) {
                return (
                    item[`${filterParam[0]}`] !== '' &&
                    item[`${filterParam[0]}`] !== null
                )
            } else {
                return item;
            }
        })
        return filterData;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer >
                <GridToolbarExport
                    printOption={{ hideFooter: true, hideToolbar: true }}
                    csvOptions={{
                        fileName: chartItem?.title
                    }} />
            </GridToolbarContainer>
        );
    }
    return (
        <>
            {
                chartItem?.data?.length > 1 &&
                <div className="col-md-12 col-sm-12 col-12 d-flex mb-2 justify-content-center">
                    {
                        arrayUniqueByKey?.length > 1 &&
                        <div className="btn-group" role="group" aria-label="Basic example">
                            {
                                arrayUniqueByKey?.map(item =>
                                    <button
                                        type="button"
                                        className={alignment == item.groupId ? `btn btn-primary ${item?.surveyType?.replace(/\s+/g, "")}` : 'btn btn-light buttonToggleBg'}
                                        onClick={(e) => handleAlignment(e, item.groupId)}
                                    >
                                        {item.groupName}
                                    </button>
                                )
                            }
                        </div>
                    }
                </div>
            }
            {
                chartItem?.data?.map((list) => (
                    list.groupId == alignment &&
                    <>
                        <div key={list.id} className="col-md-12 col-sm-12 col-12 mb-2">
                            {
                                <div className="row chartMetaHead my-2">
                                    <h4 className={`chart-title-1`}>{list?.name || list?.surveyType}</h4>
                                    <div className='row'>
                                        {
                                            list?.metaData.length > 0 &&
                                            list.metaData.map((item, index) =>
                                                <>
                                                    <div className="col-md-3 col-sm-12 col-12">
                                                        <p>{list?.surveyType} N={item?.payers} </p>
                                                    </div>
                                                    {
                                                        item?.lives > 0 &&
                                                        <div className="col-md-8 col-sm-12 col-12">
                                                            <p>{selectedLOBValue?.name} Lives N={millionConvert(item?.lives)} </p>
                                                        </div>
                                                    }

                                                </>
                                            )}

                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div style={{ height: 400, width: '100%' }}>
                                    <DataGrid
                                        className={`secondTable table_${list.surveyType?.replace(/\s+/g, "")}`}
                                        rows={
                                            filterRowTable(list?.rows, list?.headers?.Chart)
                                        }
                                        getRowId={() => Math.floor(Math.random() * 100000000)}
                                        columns={columnChartHeaderDef(list?.headers?.Chart, 1) || []}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 10 },
                                            },
                                        }}
                                        pageSizeOptions={[5, 10, 20, 30, 50]}
                                        hideFooterSelectedRowCount
                                        getRowHeight={() => 'auto'}
                                        sx={{
                                            '& .MuiDataGrid-cell': {
                                                py: '8px',
                                            },
                                        }}
                                        slots={{ toolbar: CustomToolbar }}
                                        slotProps={{ toolbar: { printOption: { disableToolbarButton: true } } }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ))
            }
            <div className='col-md-12 col-sm-12'>
                <ChartFooter data={chartItem} />
            </div>
        </>

    )
}