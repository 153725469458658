import { displayPophoverTable } from "./ChartUtility";
import { defineColorCode, SERVEY_TYPE_PAYER } from "./ChartColorConfig";

export const OptionConfig = (chartItem, chartData) => {
    const options = {
        chart: {
            type: 'bar',
            height: 350,
            marginBottom: 30,
            marginTop: 30
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        title: {
            text: '',
            align: 'center'
        },
        lang: {
            noData: 'No Data For Selected Filters'
        },
        noData: {
            style: {
                fontSize: '14px !important',
                fill: '#000 !important',
            }
        },
        xAxis: {
            categories: chartItem?.categories.map(({ name }) => name),
            title: {
                text: null
            },
            labels: {
                style: {
                    fontSize: '14px',
                    fontWeight: 800,
                    color: "#000"
                }
            }
        },
        // colors: ["#78c6cd", "#39576C", "#a887e0"],
        colors: defineColorCode(chartItem?.surveyType?.replace(/\s+/g, ""), chartItem, chartData),
        yAxis: {
            visible: false,
            tickPositioner() {
                const tickPositions = [];
                for (let i = 1; i < 21; i++) {
                    tickPositions.push(100 / 20 * i)
                }
                tickPositions.unshift(0)
                return tickPositions
            },
        },
        tooltip: {
            // valueSuffix: ' %',
            outside: true,
            useHTML: true,
            formatter: function () {
                if (chartItem?.surveyType === SERVEY_TYPE_PAYER) {
                    const chartCategaory = chartItem?.categories?.filter(item => item.name == this.key)[0];
                    const filterData = chartData?.chartId > 38 ?
                        chartItem?.series?.length > 1 ?
                            chartItem?.baseData?.rows?.filter(item => (item.OptionId == this.series.options.id && item.SeriesValue == chartCategaory.id) || (item.OptionId == chartCategaory.id && item.SeriesValue == this.series.options.id))
                            :
                            chartItem?.baseData?.rows?.filter(item => item.OptionId == chartCategaory.id || item.SeriesValue == chartCategaory.id)
                        : chartItem?.baseData?.data?.filter(item => item.filterColumn == chartCategaory.id && item?.copayProgramType == this.series.name)

                    const headerKey = chartData?.chartId > 38 ? chartItem?.baseData?.headers?.MouseoverOverlay?.[0] || "Organization" : Object.keys(chartItem?.baseData?.mouseoverOverlay)[0];
                    const uniqueList = filterData.filter((obj, index) => {
                        return index === filterData.findIndex(o => obj?.[headerKey] === o?.[headerKey]);
                    }).slice(0, 10);
                    const displayResult = displayPophoverTable(uniqueList, headerKey)
                    return displayResult;
                } else {
                    return `${this.x}<br/> <span style="color:${this.series.color}">\u25CF</span> ${this.series.name}: <b> ${this.y.toFixed(1)}% </b>`;
                }
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    // format: '{y}%',
                    formatter: function () {
                        return `${this.y.toFixed(1)}%`
                    },
                },
                events: {
                    legendItemClick: function () {
                        return false; // <== returning false will cancel the default action
                    }
                }
            }
        },
        legend: {
            align: 'center',
            verticalAlign: 'top',
            y: -16,
            floating: true,
        },
        credits: {
            enabled: false
        },
        series: chartItem?.series
    }
    return options;
} 