import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import App from './App';

const LoginButton = () => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    return (
        <>
            {
                isAuthenticated ?
                    <App />
                    :
                    loginWithRedirect()// <button onClick={() => loginWithRedirect()}>Log In</button>
            }
        </>
    )
};

export default LoginButton;