import React from 'react';
import { useNavigate } from 'react-router-dom';
import { selectModule, removeSelectTopic } from '../../../Redux/Actions/selecetdItemsSlice';
import { useDispatch } from 'react-redux';
import "../Home.css";

export const ModuleCard = ({ list }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = (list) => {
        if (list.isAuthorized) {
            dispatch(selectModule(list));
            dispatch(removeSelectTopic())
            list.moduleId === 1 && navigate('/topics');
            list.url && window.open(list.url, "_blank");
        }
    };

    return (
        <>
            <div className="my-3 me-3 modulecard">
                <div className="card shadow bg-white rounded">
                    <div className="card-header bg-transparent"><h5 className="text-dark my-0">{list.name}</h5></div>
                    <img src={`assets/images/modules/moduleid-${list.moduleId || 5}.jpg`} height="100" alt="Card image cap" />
                    <div className="card-body ">
                        <p className="card-text">{list.description}</p>
                    </div>
                    <div className="card-footer bg-transparent" align="right">
                        <button type="button" onClick={() => { handleClick(list) }} className={`btn ${!list.moduleId ? "btn-secondary" : list.isAuthorized ? "btn-primary" : "btn-info"}`}>
                            {list.btnLable ? list.btnLable : list.isAuthorized ? "Launch" : "Contact us to subscribe"}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )

}