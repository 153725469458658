import * as React from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport
} from '@mui/x-data-grid';
import { overlayColumnDef } from "./ColumnConfig";
import "./style.css";

export const OverlayTable = ({ chartItem, dataFilter }) => {

    const filterRowTable = (rowList, filterParam) => {
        const filterData = rowList?.filter(item =>
            Object.keys(filterParam)?.every(key => (item[key] || item[key] === 0))
        )
        return filterData;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer >
                <GridToolbarExport
                    printOption={{ hideFooter: true, hideToolbar: true }}
                    csvOptions={{
                        fileName: chartItem?.title
                    }} />
            </GridToolbarContainer>
        );
    }

    return (
        <>
            <div style={{ height: 400, width: '100%' }} data-html2canvas-ignore>
                <DataGrid
                    className={`secondTable table_${chartItem?.data[0]?.surveyType?.replace(/\s+/g, "")}`}
                    rows={filterRowTable(dataFilter, chartItem?.data[0]?.baseData?.headers) || []}
                    getRowId={() => Math.floor(Math.random() * 100000000)}
                    columns={overlayColumnDef(chartItem?.data[0]?.baseData.headers) || []}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 20, 30, 50]}
                    hideFooterSelectedRowCount
                    getRowHeight={() => 'auto'}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            py: '8px',
                        },
                    }}
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{ toolbar: { printOption: { disableToolbarButton: true } } }}
                />
            </div>
        </>
    );
}