import {
    ChartColors,
    ChartAlgin,
    showLegends,
    defaultChartColor,
    defaultBarAlign,
    chartCategoryHide
} from './ChartConst';
import { displayPophoverTable } from "./ChartUtility";
import { defineColorCode, SERVEY_TYPE_PAYER } from './ChartColorConfig';

export const OptionConfigNoPercent = (chartItem, chartData, listId) => {
    const options = {
        chart: {
            type: 'bar',
            height: ChartAlgin?.[chartData?.chartId]?.height || defaultBarAlign?.height,
            marginBottom: ChartAlgin?.[chartData.chartId]?.bottom || defaultBarAlign?.bottom,
            marginTop: ChartAlgin?.[chartData.chartId]?.top || defaultBarAlign?.top,
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        title: {
            text: chartItem?.axisScale.length > 0 ? `${chartItem?.axisScale?.[0]?.min} ------------------------> ${chartItem?.axisScale?.[0]?.max}`: '',
            align: 'center',
            y: 3,
            style: {
                color: '#000',
                fontSize: '15px !important',
                fontWeight: 600,
            }
        },
        lang: {
            noData: 'No Data For Selected Filters'
        },
        noData: {
            style: {
                fontSize: '14px !important',
                fill: '#000 !important',
            }
        },
        xAxis: {
            visible: !chartCategoryHide?.some(item => item == chartData.chartId),
            categories: chartItem?.categories.map(({ name, count }) => count ? `${name} (N=${count})` : name),
            title: {
                text: null
            },
            labels: {
                style: {
                    fontSize: '14px',
                    fontWeight: 800,
                    color: "#000"
                }
            },
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0
        },
        // colors: ChartColors?.[chartData.chartId]?.[listId] || defaultChartColor,
        colors: defineColorCode(chartItem?.surveyType?.replace(/\s+/g, ""), chartItem, chartData),
        yAxis: {
            visible: false,
            tickPositioner() {
                const tickPositions = [];
                for (let i = 1; i < 5; i++) {
                    tickPositions.push(i)
                }
                tickPositions.unshift(0)
                return tickPositions
            },
        },
        tooltip: {
            // valueSuffix: ' ',
            outside: true,
            useHTML: true,
            formatter: function () {
                if (chartItem?.surveyType === SERVEY_TYPE_PAYER) {
                    const chartCategaory = chartItem?.categories?.filter(item => item.name == this.key)[0];
                    const filterData = chartData?.chartId > 38 ?
                        chartItem?.series?.length > 1 ?
                            chartItem?.baseData?.rows?.filter(item => (item.OptionId == this.series.options.id && item.SeriesValue == chartCategaory.id) || (item.OptionId == chartCategaory.id && item.SeriesValue == this.series.options.id))
                            :
                            chartItem?.baseData?.rows?.filter(item => item.OptionId == chartCategaory.id || item.SeriesValue == chartCategaory.id)
                        : chartItem?.baseData?.data?.filter(item => item.filterColumn == chartCategaory.id)

                    const headerKey = chartItem?.baseData?.headers?.MouseoverOverlay?.[0] || "Organization";
                    const uniqueList = filterData.filter((obj, index) => {
                        return index === filterData.findIndex(o => obj?.[headerKey] === o?.[headerKey]);
                    }).slice(0, 10);
                    const displayResult = displayPophoverTable(uniqueList, headerKey)
                    return displayResult;
                } else {
                    return `${this.x}<br/> <span style="color:${this.series.color}">\u25CF</span> ${this.series.name}: <b> ${this.y.toFixed(1)} </b>`;
                }
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    // format: '{y}'
                    formatter: function () {
                        return `${this.y.toFixed(1)}`
                    },
                },
                events: {
                    legendItemClick: function () {
                        return false; // <== returning false will cancel the default action
                    }
                }
            },
            series: {
                cursor: 'pointer',
                groupPadding: 0.09,
                pointPadding: 0,
                point: {
                    events: {
                        click: function () {
                        }
                    }
                }

            }
        },
        legend: {
            enabled: showLegends.find(item => item === chartData?.chartId) ? true : false,
            align: 'center',
            verticalAlign: 'top',
            y: 10,
            floating: true,
        },
        credits: {
            enabled: false
        },
        series: chartItem?.series
    }
    return options;
} 