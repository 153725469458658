import React, { useEffect, useState } from 'react';
import { TopicHeader } from './TopicHeader';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { useGetTopicQuery } from '../../Redux/Actions/moduleSlice';
import { getYear, getQuarter } from '../../Constants/Constant';
import { useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TopicItemReportType } from "./TopicItemReportType";
import { removeSelectTopic } from '../../Redux/Actions/selecetdItemsSlice';
import { useDispatch } from 'react-redux';


export const Topic = () => {
    const selectedModule = useSelector((state) => state.selectedItems.selectedModules)
    const selectePrevYear = useSelector((state) => state.selectedItems.selectedTopic)
    const token_id = useSelector((state) => state.selectedItems.token_id);

    const dispatch = useDispatch();
    const param = {
        moduleId: selectedModule?.moduleId,
        token: token_id
    }

    const { data, error, isLoading } = useGetTopicQuery(param);

    const [maserDataList, setDataList] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [filterData, setFilterData] = useState([]);

    const [year, setYear] = React.useState();

    const [selectModule, setSelectModule] = React.useState();
    const [subModuleFilterData, setSubMuduleFilterData] = useState([]);

    const handleSubModule = (param) => {
        setSelectModule(param);
    };

    const uniqueFunc = (filterData) => {
        const arrayUnique = [...new Map(filterData?.map(item =>
            [item['subModuleId'], item])).values()].sort((a, b) => a?.subModuleId - b?.subModuleId);
        return arrayUnique;
    }
    const filterSubModuleList = filterData?.filter(list => list.subModuleId)
    const arrayUniqueByKey = uniqueFunc(filterSubModuleList);


    useEffect(() => {
        if (arrayUniqueByKey) {
            const defaultSubModule = arrayUniqueByKey?.find(item => item.subModuleId == selectePrevYear?.subModuleId) ? selectePrevYear?.subModuleId : arrayUniqueByKey[0]?.subModuleId;
            setSelectModule(defaultSubModule);
        }
    }, [year])

    useEffect(() => {
        const filterArrayList = filterData?.filter(list => list.subModuleId == selectModule)
        setSubMuduleFilterData(filterArrayList);
    }, [selectModule, filterData])

    const handleChange = (event) => {
        const filterArrayList = maserDataList?.filter(list => list.year == event.target.value)
        const sortedDatesDesc = filterArrayList.slice().sort((a, b) => {
            return a?.completedDate > b?.completedDate ? -1
                : a?.completedDate < b?.completedDate ? 1 : 0
        })
        setFilterData(sortedDatesDesc)
        dispatch(removeSelectTopic());
        setYear(event.target.value);
    };

    useEffect(() => {
        if (data) {
            const dataList = data?.map(list => {
                return {
                    ...list,
                    year: getYear(list?.completedDate),
                    quarter: getQuarter(list?.completedDate)
                }
            })

            const uniqueArrayList = [...new Set(dataList.map(item => item.year))].sort((a, b) => b - a)
            const defaultYear = uniqueArrayList?.find(item => item == selectePrevYear?.year) ? selectePrevYear?.year : uniqueArrayList[0];

            const filterArrayList = dataList?.filter(list => list.year == defaultYear)
            const sortedDatesDesc = filterArrayList.slice().sort((a, b) => {
                return a?.completedDate > b?.completedDate ? -1
                    : a?.completedDate < b?.completedDate ? 1 : 0
            })
            setYear(defaultYear);
            setDataList(dataList);
            setFilterData(sortedDatesDesc);
            setYearList(uniqueArrayList);
        }
    }, [data])

    return (
        <div className="container-fluid">
            <TopicHeader />
            <div className="row">
                {
                    error ?
                        <p className="text-warning">Something went wrong try again</p>
                        :
                        isLoading ?
                            <LoadingSpinner /> :
                            <>
                                <div className="row mt-2 border-bottom">
                                    <div className="col-md-2 col-sm-3 col-0">
                                    </div>
                                    <div className="col-md-7 col-sm-2 col-6 d-flex mb-2">
                                        {

                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                {
                                                    arrayUniqueByKey?.length > 0 && arrayUniqueByKey?.map(item =>
                                                        <button
                                                            type="button"
                                                            className={selectModule == item.subModuleId ? 'btn btn-primary py-4 px-4' : 'btn btn-light py-4 px-4 buttonToggleBg'}
                                                            onClick={(e) => handleSubModule(item.subModuleId)}
                                                        >
                                                            {item.submoduleName}
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-2 col-8 float-end">
                                        {
                                            year &&
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={year}
                                                    label="year"
                                                    onChange={handleChange}
                                                >
                                                    {
                                                        yearList.map(list => (
                                                            <MenuItem value={list}>{list}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        }
                                    </div>
                                </div>
                                <div>
                                    {
                                        subModuleFilterData[0]?.subModuleId == selectModule &&
                                            filterData ?
                                            <TopicItemReportType
                                                subModuleFilterData={subModuleFilterData}
                                                selectedSubModule={selectModule}
                                            />
                                            :
                                            null
                                    }
                                </div>
                            </>

                }
            </div>
        </div>
    )

}