import React, { useState, useCallback, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { OptionConfig } from './ChartConfig/BarWithStack';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ChartFooter } from './ChartFooter';
import { millionConvert } from '../../../Constants/Constant';
import { useSelector } from 'react-redux';
import { PopOverOverlayTableView } from "../Tables/CommonTabularComponents/PopOverOverlayTableView";
import { CustomToolTip } from './ChartConfig/CustomToolTip';
import { payerPopOverlay, chartEvent } from './ChartConfig/ChartConst';
import { ToggleOverlayTableView } from "../Tables/CommonTabularComponents/ToggleOverlayTableView";
import { OverlayTable } from '../Tables/OverlayTable';
import { OverlayPlotTableView } from "../Tables/CommonTabularComponents/OverlayPlotTableView";
import "../Chart.css";
NoDataToDisplay(Highcharts);

export const BarWithStackChart = ({ chartItem }) => {
    const [chart, setChart] = useState(null);
    const callback = useCallback((chart) => {
        setChart(chart);
    }, []);

    const toggleValue = useSelector((state) => state.selectedItems.selectedQToggle);
    const selectedLOBValue = useSelector((state) => state.selectedItems.selectedLOBValue);
    const [show, setShow] = useState(false);
    const [dataFilter, setDataFilter] = useState([]);
    const [selectIndex, setSelectIndex] = useState();
    const [selectedCategoryValue, setSelectedCategoryValue] = useState();
    const [selectedSeriesValue, setSelectedSeriesValue] = useState();
    const [selectedPlot, setSelectedPlot] = useState();

    const handleShow = (props, id, name, category, index, overlayData) => {
        const getResponseID = chartItem?.data[index]?.baseData?.rows?.filter(item =>
            (item?.OptionId == category.id && item?.SeriesValue == props) || (item?.OptionId == props && item?.SeriesValue == category.id)
        ).map(list => list.ResponseId)

        if (overlayData?.length > 0) {
            const selectPlotData = chartItem?.data?.filter(item => (item?.plotId == overlayData[0]?.sourceId && item?.viewType == "Overlay"))[0];
            const filterPlotData = selectPlotData?.rows?.filter(item =>
                getResponseID?.find(key => (item?.ResponseId == key))
            )
            setDataFilter(filterPlotData);
            setSelectedPlot(selectPlotData);
            setShow(true);
        } else {
            setShow(false)
        }

    };

    return (
        <>
            <div key={toggleValue}>
                {
                    chartItem?.data.map((list, i) => (
                        <div key={list.id} className="col-md-12 col-sm-6 col-12 mb-2">
                            {
                                list?.viewType == "Chart" &&
                                <div className="row chartMetaHead mb-3">
                                    <h4 className={`chart-title-1`}>{list?.name || list?.surveyType}</h4>
                                    {
                                        <div className='row'>
                                            {
                                                list?.metaData.length > 0 &&
                                                list.metaData.map((item) =>
                                                    <>
                                                        <div className="col-md-3 col-sm-12 col-12">
                                                            <p>{list?.surveyType} N={item?.payers} </p>
                                                        </div>
                                                        {
                                                            item?.lives > 0 &&
                                                            <div className="col-md-8 col-sm-12 col-12">
                                                                <p>{selectedLOBValue?.name} Lives N={millionConvert(item?.lives)} </p>
                                                            </div>
                                                        }
                                                    </>
                                                )}

                                        </div>
                                    }

                                </div>
                            }
                            {
                                list?.viewType == "Chart" &&
                                <div className="col-md-12 col-sm-12 col-12" key={list.id}>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={OptionConfig(list, chartItem, list.id, i, handleShow)}
                                    // callback= {payerPopOverlay?.find(item=> item == chartItem?.chartId) && callback}
                                    />
                                    {
                                        <CustomToolTip chart={chart} countIndex={i}>
                                            {(formatterContext) => {
                                                const { x, y, key, series } = formatterContext;

                                                const filterData = chartItem.chartId > 38 ?
                                                    chartItem?.data[i]?.baseData.rows.filter(item => item.SeriesValue == series.options.id)
                                                    : chartItem?.data[i]?.baseData.data.filter(item => item.filterColumn == series.options.id)

                                                return (
                                                    <div style={{ width: "350px", backgroundColor: "#FFF", zIndex: 1 }}>
                                                        {
                                                            chartItem.chartId > 38 ?
                                                                <PopOverOverlayTableView
                                                                    dataFilter={filterData}
                                                                    chartItem={chartItem}
                                                                />
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                );
                                            }}
                                        </CustomToolTip>
                                    }
                                </div>
                            }
                        </div>
                    ))
                }
            </div >
            {
                show &&
                <div className='row' >
                    {
                        chartItem.chartId > 38 ?
                            <OverlayPlotTableView
                                dataFilter={dataFilter}
                                chartItem={chartItem}
                                selectedPlot={selectedPlot}
                            />
                            :
                            <OverlayTable
                                dataFilter={dataFilter}
                                chartItem={chartItem}
                            />
                    }
                </div>
            }
            <div className='col-md-12 col-sm-12'>
                <ChartFooter data={chartItem} />
            </div>
        </>

    )
}