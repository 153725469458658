import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { StackOptionConfig } from './ChartConfig/StackChartConfig';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ChartFooter } from './ChartFooter';
import { OverlayTable } from '../Tables/OverlayTable';
import { chartEvent } from './ChartConfig/ChartConst';
import { useSelector } from 'react-redux';
import "../Chart.css";
NoDataToDisplay(Highcharts);

export const PlainOneRowStackGraph = ({ chartItem }) => {
    const [show, setShow] = useState(false);
    const [dataFilter, setDataFilter] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const toggleValue = useSelector((state) => state.selectedItems.selectedQToggle);

    const handleShow = (props, id) => {
        if (chartEvent[chartItem.chartId].find(item => item == props)) {
            const filterData = chartItem?.data[0]?.baseData.data.filter(item => item.filterColumn == props).filter(list => list.scriptVolumeIncreaseReason !== "" && list.scriptVolumeIncreaseReason !== null)
            setDataFilter(filterData);
            setSelectedValue(props);
            setShow(true)
        } else {
            setShow(false)
        }
    };

    useEffect(() => {
        if (selectedValue) {
            const filterData = chartItem?.data[0]?.baseData.data.filter(item => item.filterColumn == selectedValue)
            setDataFilter(filterData);
        }
    }, [chartItem])

    return (
        <>
            {
                chartItem?.data.map((list) => (
                    <div className={`col-md-12 col-sm-12 col-12`} key={list.id}>
                        <div key={toggleValue} className='row chartPanel'>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={StackOptionConfig(list, chartItem, list.id, handleShow)}
                            />
                        </div>
                    </div>
                ))
            }
            {
                show &&
                <div className='row' >
                    <OverlayTable
                        dataFilter={dataFilter}
                        chartItem={chartItem}
                    />
                </div>
            }
            <div className='col-md-12 col-sm-12'>
                <ChartFooter data={chartItem} />
            </div>
        </>

    )
}