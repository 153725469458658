import { millionConvert, millionConvertValue } from '../../../Constants/Constant';
import { tableHeadConst, tableColumnFormate } from './TableConst';

export const columnDef = (header) => {
    let colHead = [];

    for (const [key, value] of Object.entries(header)) {

        const param = {
            field: key.toLowerCase(),
            headerName: value,
            flex: 1,
            hideable: false,
            rowGrouping: true,
            valueGetter: (params) => (
                key == "column2" || key == "lives" || key == "Lives" ||
                key == "lives (Millions)" || key == "Lives (Millions)" ||
                key == "column2 (Millions)"
            ) ? millionConvertValue(params.row[key]) : params.row[key],
        }
        colHead.push(param)
    }
    return colHead;
}


export const overlayColumnDef = (header) => {
    let colHead = [];

    for (const [key, value] of Object.entries(header)) {

        const param = {
            field: key.toLowerCase(),
            headerName: value,
            flex: 1,
            hideable: false,
            valueGetter: (params) => (key == "lives" || key == "lives (Millions)") ? millionConvertValue(params.row[key]) : params.row[key],
        }
        colHead.push(param)
    }
    return colHead;
}

export const columnHeaderDef = (header, chartId, id) => {
    let colHead = [];

    for (const [key, value] of Object.entries(header)) {
        const param = {
            field: key.toLowerCase(),
            headerName: value,
            flex: 1,
            hideable: false,
            valueGetter: (params) => {
                if (tableColumnFormate?.find(item => item === chartId)) {
                    return tableHeadConst[chartId][id]?.find(item => item === key) ? millionConvertValue(params.row[key]) : params.row[key]
                } else {
                    return params.row[key]
                }
            },
        }
        colHead.push(param)
    }
    return colHead;
}



export const columnChartHeaderDef = (header, type) => {
    const headerList = header?.map(list => {
        return {
            field: list,
            headerName: list?.replace(TableTypeView[type], ""),
            flex: 1,
            hideable: false,
            rowGrouping: true,
            valueGetter: (params) => (
                list == "Payer Lives" || list == "lives" || list == "Lives" || list == "Lives Count" ||
                list == "Lives (Millions)" || list == "Lives Count (Millions)" || list == "Payer Lives (Millions)" ||
                list == "lives (Millions)"
            )
                ? millionConvertValue(params?.row[list]) : trimString(params?.row[list]),
        }
    })
    return headerList || [];
}

const trimString = (value) => {
    if (value.indexOf('\n') == 0 || value.indexOf('\n\r') == 0 || value.indexOf('\r') == 0) {
        return value.substr(1, value.length);
    } else {
        return value
    }
}

const TableTypeView = {
    1: ".Chart",
    2: ".Tabular",
    3: ".Overlay",
    4: ".Overlay",
}