import { ChartColors, ChartAlgin, MeanArea } from './ChartConst';
import { defineColorCode } from './ChartColorConfig';

export const OptionConfig = (chartItem, chartData, listId) => {
    const options = {
        chart: {
            type: 'bar',
            height: 350,
            marginBottom: 30,
            marginTop: 20
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        title: {
            text: '',
            align: 'center'
        },
        lang: {
            noData: 'No Data For Selected Filters'
        },
        noData: {
            style: {
                fontSize: '14px !important',
                fill: '#000 !important',
            }
        },
        xAxis: {
            categories: chartItem?.categories.map(({ name }) => name),
            title: {
                text: null
            },
            labels: {
                style: {
                    fontSize: '14px',
                    fontWeight: 800,
                    color: "#000"
                }
            }
        },
        // colors: ChartColors[chartData.chartId][listId], //chartItem.id == 1 || chartItem.id == 3? ["#78C6CD", "#588A8E"]: ["#588A8E"],
        colors: defineColorCode(chartItem?.surveyType?.replace(/\s+/g, ""), chartItem, chartData),
        yAxis: {
            visible: false,
            tickPositioner() {
                const tickPositions = [];
                for (let i = 1; i < 11; i++) {
                    tickPositions.push(100 / 10 * i)
                }
                tickPositions.unshift(0)
                return tickPositions
            },
        },
        tooltip: {
            // valueSuffix: ' %',
            formatter: function () {
                return `${this.y.toFixed(1)}%`
            },
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return `${this.y.toFixed(1)}%`
                    },
                },
                events: {
                    legendItemClick: function () {
                        return false; // <== returning false will cancel the default action
                    }
                }
            }
        },
        legend: {
            enabled: false,
            align: 'center',
            verticalAlign: 'bottom',
            y: 10,
            floating: true,
        },
        credits: {
            enabled: false
        },
        series: chartItem?.series
    }
    return options;
} 