import React from 'react';
import { LoadingSpinner } from '../../../Components/LoadingSpinner';
import { useGetTopicQuery } from '../../../Redux/Actions/chartDataSlice';
import { GetPlot, GetTable } from '../Charts/commonChartNav';
import { useSelector } from 'react-redux';
import "../Chart.css";

export const ChartCard = ({ list, selectedFilterItems, activeArea }) => {
    const selectedTopic = useSelector((state) => state.selectedItems.selectedTopic);
    const token_id = useSelector((state) => state.selectedItems.token_id)

    const param = {
        chartId: list?.chartId,
        year: selectedTopic?.year,
        selectedFilterItems: selectedFilterItems,
    }
    const { data, error, isLoading } = useGetTopicQuery({param, token_id});
    return (
        <>
            {
                error ?
                    <p className="text-center fw-bold">Something went wrong try again</p>
                    :
                    isLoading ?
                        <LoadingSpinner /> :
                        data ?
                        <>
                            {
                                activeArea == 2 &&
                                    data.Data !== null ?
                                    <>
                                        <div className='row'>
                                            {GetPlot(list, data)}
                                        </div>
                                    </>                                    
                                    :
                                    <p className="text-center fw-bold" style={{ color: 'rgb(102, 102, 102)' }}>
                                        {data.Message} </p>
                            }
                            {
                                activeArea == 4 &&
                                    data.Data !== null && data.data.length > 0 &&
                                    <>
                                        <div className='row'>
                                            {GetTable(list, data)}
                                        </div>
                                    </>
                                    
                            }
                        </>
                        : <p className="text-center fw-bold">No Data For Selected Filters</p>

            }
        </>
    )
}