import React, { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FilterBox } from './FilterBox';
import { getFilterData } from '../../../Services/TopicService';
import { useDispatch, useSelector } from 'react-redux';
import { updateGlobalFilter, updateLOB } from '../../../Redux/Actions/selecetdItemsSlice';

export const FilterPanelContainer = ({ handleClose, show, setSelectedFilterItems, subTopickey }) => {
    const initiate = {
        "LOB": 1
    }
    const [filterList, setFilterList] = useState();
    const [selectedFilters, setSelectedFilters] = useState({});

    const dispatch = useDispatch();
    const selectedTopic = useSelector((state) => state.selectedItems.selectedTopic);
    const token_id = useSelector((state) => state.selectedItems.token_id)

    useEffect(() => {
        const param = {
            subTopicId: subTopickey,
            year: selectedTopic?.year,
            token_id
        }

        getFilterData(param).then(resp => {
            setFilterList(resp);
            setLOBFilterValues(resp, initiate);
        })
        setSelectedFilters(initiate)
        setSelectedFilterItems(initiate)
        dispatch(updateGlobalFilter(initiate));
    }, [subTopickey])

    const applyFilter = () => {
        setSelectedFilterItems(selectedFilters);
        dispatch(updateGlobalFilter(selectedFilters));
        setLOBFilterValues(filterList, selectedFilters);
    }

    const setLOBFilterValues = (list, filterParam) => {
        const singleFilterList = list?.filter(item => item.name === "LOB")[0];
        const filterlistItem = singleFilterList?.values?.filter(item => item.id == filterParam.LOB)[0];
        dispatch(updateLOB(filterlistItem));
        handleClose();
    }

    const resetFilter = () => {
        let initial = {
            ...selectedFilters
        }
        for (const key in initial) {
            initial[key] = [];
        }
        initial = {
            ...initial,
            ...initiate
        }
        setSelectedFilters(initial);
        setSelectedFilterItems(initial);
        dispatch(updateGlobalFilter(initial));
        setLOBFilterValues(filterList, initiate);
    }
    return (
        <>
            <Offcanvas className="filterPanel" show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header className="border-bottom" closeButton>
                    <h4 className="m-0" id="offcanvasRightLabel">Filters</h4>
                </Offcanvas.Header>
                {
                    // subTopickey == 1 ||
                    //     subTopickey == 2 ||
                    //     subTopickey == 3
                    //     ?
                        <>
                            <Offcanvas.Body>
                                {
                                    filterList &&
                                    filterList?.map((filterItem) => (
                                        <div key={`${filterItem.name}-${filterItem.filterId}`}>
                                            <h5 className="text-dark my-1">{filterItem.name}</h5>
                                            <FilterBox filterItemList={filterItem} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
                                        </div>
                                    ))
                                }.
                            </Offcanvas.Body>
                            <div className="offcanvas-footer py-1 border-top text-center">
                                <button type="button" onClick={() => applyFilter()} className="btn btn-primary">
                                    Apply
                                </button>
                                <button type="button" onClick={() => resetFilter()} className="btn btn-primary mx-3 ">
                                    Reset
                                </button>
                            </div>
                        </>
                        // : <WorkProgress />
                }

            </Offcanvas>
        </>
    );
}
