import React from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport
} from '@mui/x-data-grid';
import { columnDef } from "../ColumnConfig";
import "../style.css";


export const DataTable = ({ chartItem }) => {
    function CustomToolbar() {
        return (
            <GridToolbarContainer >
                <GridToolbarExport
                    printOption={{ hideFooter: true, hideToolbar: true }}
                    csvOptions={{
                        fileName: chartItem?.title
                    }} />
            </GridToolbarContainer>
        );
    }
    return (
        <>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    className={`secondTable table_${chartItem?.data[0]?.surveyType?.replace(/\s+/g, "")}`}
                    rows={chartItem?.data[0]?.baseData.data || []}
                    getRowId={() => Math.floor(Math.random() * 100000000)}
                    columns={columnDef(chartItem?.data[0]?.baseData?.tableHeaders) || []}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                        rowGrouping: {
                            model: ['copayProgramType'],
                        },
                    }}
                    pageSizeOptions={[5, 10, 20, 30, 50]}
                    hideFooterSelectedRowCount
                    getRowHeight={() => 'auto'}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            py: '8px',
                        },
                    }}
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{ toolbar: { printOption: { disableToolbarButton: true } } }}
                />
            </div>
        </>
    );
}