import {
    ChartColors,
    ChartAlgin,
    chartEvent,
    defaultChartColor,
    defaultBarStackAlign,
    barStackMultiToggle,
    chartHightPlotId
} from './ChartConst';
import { descSort } from '../../../../Constants/Constant';
import { displayPophoverTable } from "./ChartUtility";
import { defineColorCode, SERVEY_TYPE_PAYER } from './ChartColorConfig';

export const OptionConfig = (chartItem, chartData, listId, i, handleShow) => {
    const options = {
        chart: {
            type: 'bar',
            height: chartHightPlotId.find(item=> item ==chartData?.chartId) ? ChartAlgin?.[chartData?.chartId]?.[chartItem?.plotId]?.height || defaultBarStackAlign?.height: ChartAlgin?.[chartData?.chartId]?.[listId]?.height || defaultBarStackAlign?.height,
            marginBottom: chartHightPlotId.find(item=> item ==chartData?.chartId) ? ChartAlgin?.[chartData?.chartId]?.[chartItem?.plotId]?.bottom || defaultBarStackAlign?.bottom: ChartAlgin?.[chartData?.chartId]?.[listId]?.bottom || defaultBarStackAlign?.bottom,
            marginTop: chartHightPlotId.find(item=> item ==chartData?.chartId) ? ChartAlgin?.[chartData?.chartId]?.[chartItem?.plotId]?.top || defaultBarStackAlign?.top: ChartAlgin?.[chartData?.chartId]?.[listId]?.top || defaultBarStackAlign?.top,
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        title: {
            text: '',
            align: 'center'
        },
        lang: {
            noData: 'No Data For Selected Filters'
        },
        noData: {
            style: {
                fontSize: '14px !important',
                fill: '#000 !important',
            }
        },
        xAxis: {
            categories: chartItem?.categories,//.map(({ name }) => name),
            crosshair: false,
            title: {
                text: null
            },
            labels: {
                style: {
                    fontSize: '14px',
                    fontWeight: 800,
                    color: "#000"
                },
                formatter: function () {
                    // const categoryFilter = chartItem?.categories?.filter(item => item.name == this.value)[0];
                    if (this.value?.mean || this.value?.mean == 0) {
                        return `${this.value.name} <i className="fa-solid fa-circle-chevron-left"></i> <br/><b>(Mean=${this.value?.mean?.toFixed(1)})</b>`
                    } else if (this.value?.count || this.value?.count == 0) {
                        return `${this.value.name} (N=${this.value.count})`
                    } else {
                        return this.value.name;
                    }
                },
            },
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0
        },
        // colors: barStackMultiToggle?.find(item => item == chartData.chartId) ? ChartColors?.[chartData?.chartId]?.[listId] || defaultChartColor : ChartColors[chartData.chartId] || defaultChartColor,
        colors: defineColorCode(chartItem?.surveyType?.replace(/\s+/g, ""), chartItem, chartData),
        yAxis: {
            visible: false,
            tickPositioner() {
                const tickPositions = [];
                for (let i = 1; i < 21; i++) {
                    tickPositions.push(100 / 20 * i)
                }
                tickPositions.unshift(0)
                return tickPositions
            },
        },
        tooltip: {
            // valueSuffix: ' %',
            // shared: true,
            outside: true,
            useHTML: true,
            formatter: function () {
                if (chartItem?.surveyType === SERVEY_TYPE_PAYER) {
                    const chartCategaory = this.key; //chartItem?.categories?.filter(item => item.name == this.key)[0];

                    const filterData = chartData?.chartId > 38 ?
                        chartItem?.baseData?.rows?.filter(item => (item.OptionId == this.series.options.id && item.SeriesValue == chartCategaory.id) ||
                            item.OptionId == chartCategaory.id && item.SeriesValue == this.series.options.id
                        )
                        : chartItem?.baseData?.data?.filter(item => item.filterColumn == this.series.options.id)

                    const headerKey = chartItem?.baseData?.headers?.MouseoverOverlay?.[0] || "Organization";
                    const uniqueList = filterData.filter((obj, index) => {
                        return index === filterData.findIndex(o => obj?.[headerKey] === o?.[headerKey]);
                    }).slice(0, 10);
                    const displayResult = displayPophoverTable(uniqueList, headerKey)
                    return displayResult;
                } else {
                    return `${this.x.name}<br/> <span style="color:${this.series.color}">\u25CF</span> ${this.series.name}: <b> ${this.y.toFixed(1)}% </b>`;
                }
            }
        },
        plotOptions: {
            bar: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    outside: true,
                    align: 'center',
                    verticalAlign: 'bottom',
                    y: 25,
                    style: {
                        fontWeight: 800,
                        color: "#000"
                    },
                    formatter: function () {
                        if (this.series.options.overlay?.length > 0) {
                            return `${this.y >= 5 ? `${this.y.toFixed(1)}%` : ''} <span><span style="color:#000;font-size:16px !important"><i class="fa-solid fa-eye fa-lg"></i></span></span>`
                        } else {
                            return this.y >= 5 && `${this.y.toFixed(1)}%`
                        }
                    },
                },
                events: {
                    click: function (e) {
                        if (e.point.series.options.overlay?.length > 0) {                            
                            handleShow(e.point.series.options.id, listId, e.point.series.options.name, e.point.category, i, e.point.series.options.overlay)
                        } else {
                            handleShow();
                        }
                    },
                    legendItemClick: function () {
                        return false;
                    }
                }
            }
        },
        legend: {
            align: 'center',
            verticalAlign: 'top',
            y: -16,
            floating: true,
            reversed: true,
        },
        credits: {
            enabled: false
        },
        series: descSort(chartItem?.series),//chartItem?.series
    }
    return options;
} 