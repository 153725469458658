import {
    ChartColors,
    ChartAlgin,
    chartEvent,
    defaultChartColor,
    defaultStackAlign
} from './ChartConst';
import { descSort } from '../../../../Constants/Constant';
import { displayPophoverTable } from "./ChartUtility";
import { defineColorCode, SERVEY_TYPE_PAYER } from './ChartColorConfig';

export const StackOptionConfigWithXaxis = (chartItem, chartData, id, handleShow, i, length) => {
    const options = {
        chart: {
            type: 'bar',
            height: ChartAlgin?.[chartData?.chartId]?.[id]?.height || defaultStackAlign?.height,
            marginBottom: ChartAlgin?.[chartData?.chartId]?.[id]?.bottom || defaultStackAlign?.bottom,
            marginTop: ChartAlgin?.[chartData?.chartId]?.[id]?.top || defaultStackAlign?.top,
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        },
        title: {
            text: '',
            align: 'center'
        },
        lang: {
            noData: 'No Data For Selected Filters'
        },
        noData: {
            style: {
                fontSize: '14px !important',
                fill: '#000 !important',
            }
        },
        xAxis: {
            categories: chartItem?.categories.map(({ name }) => name),
            visible: false,
            lineWidth: 0,
            title: {
                text: null
            },
            labels: {
                style: {
                    fontSize: '14px',
                    fontWeight: 800,
                    color: "#000"
                }
            },
        },
        // colors: ChartColors?.[chartData?.chartId]?.[id] || defaultChartColor,
        colors: defineColorCode(chartItem?.surveyType?.replace(/\s+/g, ""), chartItem, chartData),
        yAxis: {
            visible: false,
            tickPositioner() {
                const tickPositions = [];
                for (let i = 1; i < 21; i++) {
                    tickPositions.push(100 / 20 * i)
                }
                tickPositions.unshift(0)
                return tickPositions
            },
        },
        tooltip: {
            // valueSuffix: ' %',
            // shared: true,
            outside: true,
            useHTML: true,
            formatter: function () {
                if (chartItem?.surveyType === SERVEY_TYPE_PAYER) {
                    const filterData = chartData?.chartId > 38 ?
                        chartItem?.baseData?.rows?.filter(item => item.OptionId == this.series.options.id || item.SeriesValue == this.series.options.id)
                        : chartItem?.baseData?.data?.filter(item => item.filterColumn == this.series.options.id)

                    const headerKey = chartData?.chartId > 38 ? chartItem?.baseData?.headers?.MouseoverOverlay?.[0] || "Organization" : Object.keys(chartItem?.baseData?.mouseoverOverlay)[0];
                    const uniqueList = filterData.filter((obj, index) => {
                        return index === filterData.findIndex(o => obj?.[headerKey] === o?.[headerKey]);
                    }).slice(0, 10);
                    const displayResult = displayPophoverTable(uniqueList, headerKey)
                    return displayResult;
                } else {
                    return `${this.x}<br/> <span style="color:${this.series.color}">\u25CF</span> ${this.series.name}: <b> ${this.y.toFixed(1)}% </b>`;
                }
            }
        },
        plotOptions: {
            bar: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                    // TODO: adding custom icons
                    outside: true,
                    align: 'center',
                    verticalAlign: 'bottom',
                    y: 25,
                    style: {
                        fontWeight: 800,
                        color: "#000"
                    },
                    useHTML: true,
                    formatter: function () {
                        const keyVal = chartData?.chartId > 38 ? this.series.options.name : this.series.options.id;
                        if (chartEvent[chartData?.chartId]?.find(item => item == keyVal)) {
                            return `${this.y >= 5 ? `${this.y.toFixed(1)}%` : ''} <span><span style="color:#000;font-size:16px !important"><i class="fa-solid fa-eye fa-lg"></i></span></span>`

                        } else {
                            return this.y >= 5 && `${this.y.toFixed(1)}%`
                        }
                    },
                },
                events: {
                    click: function (e) {
                        handleShow(e.point.series.options.id, id, e.point.series.options.name, i)
                    },
                    legendItemClick: function () {
                        return false;
                    }
                },
            },
        },
        legend: {
            align: 'center',
            verticalAlign: 'top',
            floating: true,
            reversed: true,
            enableMouseTracking: false,
            x: -20,
        },
        credits: {
            enabled: false
        },
        series: descSort(chartItem?.series)
    }
    return options;
} 