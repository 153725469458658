import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { sec } from '../../Security';

export const subTopicTabApi = createApi({
    reducerPath: 'SubTopicTab',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_MA_API}`,
        prepareHeaders: async (headers, { getState }) => {
            const access_token = await sec.getAccessTokenSilently()();
            if (access_token) {
                headers.set('Access-Control-Allow-Origin', '*');
                headers.set('Authorization', `Bearer ${access_token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getTopic: builder.query({
            query: ({ topicId }) => `topics/${topicId}/subtopics`,
        }),
    }),
})

export const { useGetTopicQuery } = subTopicTabApi