import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import "./topic.css";

export const ItemList = ({ item }) => {

    return (
        <>
            <div className='mt-5 px-2'>
                <Stack sx={{ width: '100%' }} spacing={2} className="subscriptionPanel">
                    <Alert severity="info">
                        <AlertTitle>Subscribe to launch</AlertTitle>
                        To launch these topics, Please subscribe.
                    </Alert>
                </Stack>
            </div>
            {
                item.map(list => (
                    !list.isAuthorized &&
                    <div className='mr-2 mt-2 mx-2 d-inline-flex'>
                        <div
                            className="card my-auto shadow-sm bg-white rounded h-100 align-text-bottom"
                            style={{ width: "20rem", minHeight: "6rem" }}
                        >
                            <div className="card-body d-flex align-items-center">
                                <img src={`assets/images/topics/topicId-${list.topicId || 5}.svg`} width={50} height={50} className="me-3" alt="topic icon" />
                                <h6 className="card-subtitle mb-2">{list.name}</h6>
                            </div>
                        </div>
                    </div>
                ))

            }
        </>
    )

}