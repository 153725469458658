import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { OptionConfig } from './ChartConfig/DoubleBar';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ChartFooter } from './ChartFooter';
import { millionConvert } from '../../../Constants/Constant';
import { useSelector } from 'react-redux';
import "../Chart.css";
NoDataToDisplay(Highcharts);

export const DoubleBarChart = ({ chartItem }) => {
    const toggleValue = useSelector((state) => state.selectedItems.selectedQToggle);
    const selectedLOBValue = useSelector((state) => state.selectedItems.selectedLOBValue);
    return (
        <>
            <div className="col-md-12 col-sm-12 col-12 chartMetaHead d-flex mb-2">
                {
                    chartItem?.data[0]?.metaData.length > 0 &&
                    chartItem?.data[0]?.metaData.map(item => (
                        <div key={item?.categoryId} className='col-md-6 col-sm-12 col-12 chartBorder mx-2'>
                            <h4 className={`chart-title-1`}>{chartItem?.data[0]?.name || item?.categoryName}</h4>
                            <div className='row'>
                                <div className="col-md-4 col-sm-12 col-12">
                                    <p>{chartItem?.data[0]?.surveyType} N={item?.payers} </p>
                                </div>
                                <div className="col-md-8 col-sm-12 col-12">
                                    <p>{selectedLOBValue?.name} Lives N={millionConvert(item?.lives)} </p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div key={toggleValue}>
                {
                    chartItem?.data.map((list) => (
                        <div className="col-md-12 col-sm-12 col-12" key={list.id}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={OptionConfig(list, chartItem)}
                            />
                        </div>
                    ))
                }
            </div>

            <div className='col-md-12 col-sm-12'>
                <ChartFooter data={chartItem} />
            </div>
        </>

    )
}